import { TEXT_AMOUNTS } from './constants'
import { TextAmount } from './types'
export const isContentEmpty = (contentMd: string) => {
  return contentMd.replaceAll('---', '').trim() === ''
}

export const countSectionDividers = (contentMd: string) => {
  const sections = contentMd.split('\n---\n')
  return sections.length
}

export const countContentParagraphs = (contentMd: string) => {
  const blocks = contentMd.split('\n\n')
  return blocks.length
}

export const truncateAndNumberContent = (
  contentMd: string,
  maxCards: number
) => {
  const sections = contentMd.split('\n---\n')
  const truncatedSections = sections.slice(0, maxCards)
  return truncatedSections
    .map(
      (section, i) => `--- ${i + 1}

${section}`
    )
    .join('\n\n')
}

export const findTextAmount = (len: TextAmount) =>
  TEXT_AMOUNTS[len] || TEXT_AMOUNTS.md
