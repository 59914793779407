import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReadableStream } from 'web-streams-polyfill/ponyfill'

import { RootState } from 'modules/redux'

import { AIRequestProps } from '../track'
import { CardTimeData } from '../transform/StreamTransformers/CardStreamTimer'
import { CardChunk, GenerateStreamMessage } from '../transform/types'

export type StreamResponse = {
  id: string
  status: 'running' | 'complete' | 'error' | 'aborted'
  isResuming: boolean
  docId?: string
  lastCompletedCardId?: string | null
  stream: ReadableStream<GenerateStreamMessage<CardChunk>>
}
export type AIStreamState = {
  isOtherClientStreaming: boolean
  streams: Record<string, StreamResponse>
  tracking: Record<string, AIResponseTrackingArgs>
  timing: Record<string, TimingData>
}

type TimingData = {
  tokens: number
  duration: number
  cardsCompleted: number
  isDone: boolean
}

export type AIResponseTrackingArgs = AIRequestProps & {
  interactionId: string
  startTime: number
  latency?: number
  outputContent?: string
}

const DEFAULT_AVG_TOKEN_DURATION = 50

const initialState: AIStreamState = {
  isOtherClientStreaming: false,
  streams: {},
  tracking: {},
  timing: {},
}

const AIStreamSlice = createSlice({
  name: 'AIStream',
  initialState,
  reducers: {
    reset: () => initialState,
    setOtherClientStreaming(
      state: AIStreamState,
      action: PayloadAction<boolean>
    ) {
      state.isOtherClientStreaming = action.payload
    },
    recordAIRequestSent(
      state: AIStreamState,
      action: PayloadAction<AIResponseTrackingArgs>
    ) {
      const { interactionId } = action.payload
      state.tracking[interactionId] = {
        ...state.tracking[interactionId],
        ...action.payload,
      }
    },
    recordCardTiming(
      state: AIStreamState,
      action: PayloadAction<{
        interactionId: string
        data: CardTimeData
      }>
    ) {
      const { interactionId, data } = action.payload
      state.timing[interactionId] = state.timing[interactionId] || {
        tokens: 0,
        duration: 0,
        isDone: false,
        cardsCompleted: 0,
      }

      state.timing[interactionId].tokens += data.numTokens
      state.timing[interactionId].duration += data.duration
      state.timing[interactionId].cardsCompleted++
    },
    recordAIStreamStart(
      state: AIStreamState,
      action: PayloadAction<{
        interactionId: string
        docId: string
      }>
    ) {
      const { interactionId, docId } = action.payload
      const existing = state.tracking[interactionId]
      if (!existing) {
        return
      }

      state.tracking[interactionId] = {
        ...existing,
        docId,
      }
    },
    recordAIRequestDone(
      state: AIStreamState,
      action: PayloadAction<{
        interactionId: string
        endTime: number
        outputContent?: string
      }>
    ) {
      const { interactionId, outputContent, endTime } = action.payload
      const existing = state.tracking[interactionId]
      if (!existing || !existing.startTime) {
        return
      }

      const timingData = state.timing[interactionId]
      if (timingData) {
        state.timing[interactionId] = {
          ...timingData,
          isDone: true,
        }
      }

      state.tracking[interactionId] = {
        ...existing,
        latency: endTime - existing.startTime,
      }
      if (outputContent !== undefined) {
        state.tracking[interactionId].outputContent = outputContent
      }
    },
    addStream(
      state: AIStreamState,
      action: PayloadAction<{
        stream: StreamResponse
      }>
    ) {
      const { stream } = action.payload
      state.streams[stream.id] = stream
    },
    setStreamDocId(
      state: AIStreamState,
      action: PayloadAction<{
        id: string
        docId: string
        lastCompletedCardId?: string | null
      }>
    ) {
      const { id, docId, lastCompletedCardId } = action.payload
      if (state.streams[id]) {
        state.streams[id].docId = docId
        state.streams[id].lastCompletedCardId = lastCompletedCardId
      }

      if (state.tracking[id]) {
        state.tracking[id].docId = docId
      }
    },
    abortStream(
      state: AIStreamState,
      action: PayloadAction<{
        id: string
      }>
    ) {
      const { id } = action.payload
      delete state.streams[id]
      delete state.timing[id]
      delete state.tracking[id]
    },
    completeStream(
      state: AIStreamState,
      action: PayloadAction<{
        id: string
        status: 'complete' | 'error'
      }>
    ) {
      const { id, status } = action.payload
      if (state.streams[id]) {
        state.streams[id].status = status
      }
      if (state.timing[id]) {
        state.timing[id].isDone = true
      }
    },
  },
})

export const {
  addStream,
  setStreamDocId,
  completeStream,
  abortStream,
  reset,
  recordAIStreamStart,
  recordAIRequestSent,
  recordAIRequestDone,
  recordCardTiming,
  setOtherClientStreaming,
} = AIStreamSlice.actions

type SliceState = Pick<RootState, 'AIStream'>

// Selectors

export const selectStreamForDoc =
  (docId?: string) =>
  (state: SliceState): StreamResponse | undefined => {
    if (!docId) {
      return undefined
    }
    return Object.values(state.AIStream.streams).find((s) => s.docId === docId)
  }

export const selectStreamRunningForDoc =
  (docId?: string) => (state: SliceState) => {
    if (!docId) return false
    const stream = selectStreamForDoc(docId)(state)
    return stream?.status === 'running'
  }

export const selectIsOtherClientStreaming = (state: SliceState) => {
  return state.AIStream.isOtherClientStreaming
}

export const selectAIRequestData =
  (interactionId: string) => (state: SliceState) => {
    return state.AIStream.tracking[interactionId]
  }

// set a reasonable max here, 120 means a 500 token deck generates in 60 sec
const MAX_TOKEN_SPEED = 120

export const selectStreamAvgTokenDuration =
  (interactionId: string) => (state: SliceState) => {
    const data = state.AIStream.timing[interactionId]
    if (!data) {
      return DEFAULT_AVG_TOKEN_DURATION
    }

    const { tokens, duration } = data
    if (duration === 0 || tokens === 0) {
      return DEFAULT_AVG_TOKEN_DURATION
    }

    // se the max to DEFAULT_AVG_TOKEN_DURATION, anything faster feels jarring
    return Math.max(
      DEFAULT_AVG_TOKEN_DURATION,
      Math.min(duration / tokens, MAX_TOKEN_SPEED)
    )
  }

// Reducer
export const AIStreamReducer = AIStreamSlice.reducer
