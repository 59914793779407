import { LowCreditStatus } from './types'

export const getCreditThresholdStatus = (
  credits: number | null,
  interactionCost: number,
  numOfInteractions: number
): LowCreditStatus | null => {
  if (credits === null) {
    return null
  }
  if (credits <= 0) {
    return LowCreditStatus.EXHAUSTED
  }
  if (credits <= interactionCost * numOfInteractions) {
    return LowCreditStatus.LOW
  }
  return null
}
