import { ProductFeatureMap, ProductFeatures } from '@gammatech/authorization'

import {
  Currency,
  ExistingWorkspace,
  FrequencyUnit,
  ProductPrice,
  WorkspaceSubscription,
} from 'modules/api'
import { formatCurrency } from 'modules/i18n/utils/currency'

import { PaidProductKey } from './types'

export const ZERO_DECIMAL_CURRENCIES: Currency[] = [Currency.Jpy, Currency.Krw]

export const getCurrencyDivisor = (currency: Currency | undefined) => {
  return currency ? (ZERO_DECIMAL_CURRENCIES.includes(currency) ? 1 : 100) : 100
}

export const shouldShowCurrencyDisplayAsCode = (
  currency: Currency | undefined
) => {
  return currency === Currency.Twd
}

export const getNotation = (
  currency: Currency | undefined
): Intl.NumberFormatOptions['notation'] => {
  return getCurrencyDivisor(currency) < 100 ? 'standard' : 'compact'
}

export const getPriceDisplayMonthly = (productPrice?: ProductPrice) => {
  if (
    !productPrice ||
    !productPrice.price ||
    !productPrice.frequencyInterval ||
    !productPrice.frequencyUnit
  ) {
    return '---'
  }

  const { currency, frequencyInterval, frequencyUnit, price } = productPrice

  const notation = getNotation(currency)
  if (frequencyUnit === FrequencyUnit.Month) {
    return formatCurrency(
      price / getCurrencyDivisor(currency) / frequencyInterval,
      {
        currency,
        notation,
      }
    )
  } else if (frequencyUnit === FrequencyUnit.Year) {
    return formatCurrency(
      price / getCurrencyDivisor(currency) / 12 / frequencyInterval,
      {
        currency,
        notation,
      }
    )
  }

  return '---'
}

export const getPriceDisplayAnnually = (productPrice?: ProductPrice) => {
  if (
    !productPrice ||
    !productPrice.price ||
    !productPrice.frequencyInterval ||
    !productPrice.frequencyUnit
  ) {
    return '---'
  }

  const { currency, frequencyInterval, frequencyUnit, price } = productPrice

  const notation = getNotation(currency)
  if (frequencyUnit === FrequencyUnit.Month) {
    return formatCurrency(
      (price / getCurrencyDivisor(currency) / frequencyInterval) * 12,
      {
        currency,
        notation,
      }
    )
  } else if (frequencyUnit === FrequencyUnit.Year) {
    return formatCurrency(
      price / getCurrencyDivisor(currency) / frequencyInterval,
      {
        currency,
        notation,
      }
    )
  }

  return '---'
}

const subtractMonths = (date: Date, months: number) => {
  const d = new Date(date)
  d.setMonth(d.getMonth() - months)
  return d
}

const getPercentageRemaining = (
  subscription: Pick<WorkspaceSubscription, 'nextBillingTime' | 'products'>,
  productPrice: ProductPrice
) => {
  const nextBillingTime = subtractMonths(
    new Date(subscription?.nextBillingTime),
    0 // Change this number to debug proration
  )
  const subscriptionNextBillingTime = new Date(nextBillingTime)
  const subscriptionPreviousBillingTime = subtractMonths(
    subscriptionNextBillingTime,
    productPrice.frequencyUnit === 'month' ? 1 : 12
  )

  const percentageRemaining =
    1 -
    (new Date().getTime() - subscriptionPreviousBillingTime.getTime()) /
      (subscriptionNextBillingTime.getTime() -
        subscriptionPreviousBillingTime.getTime())

  return percentageRemaining
}

/**
 * A client side utility to compute the charge for adding users to a workspace.
 * This is a best effort since the actual charge isn't known until the invitation is accepted.
 * Related issue: https://linear.app/gamma-app/issue/G-4965/show-a-preview-invoice-when-changing-user-numbers
 */
export const computeSubscriptionChangeData = (
  subscription?: Pick<WorkspaceSubscription, 'nextBillingTime' | 'products'>,
  seatCount = 1
) => {
  const productPrice = getProOrPlusProductPrice(subscription)
  if (!subscription || !productPrice?.price || !productPrice?.frequencyUnit) {
    return {}
  }

  const perSeatCost =
    productPrice.price / getCurrencyDivisor(productPrice.currency)
  const prorationFactor = getPercentageRemaining(subscription, productPrice)
  const proratedDays = Math.round(
    prorationFactor * (productPrice.frequencyUnit === 'month' ? 30 : 365)
  )
  const totalPrice = formatCurrency(+(perSeatCost * seatCount).toFixed(2), {
    currency: productPrice.currency,
  })
  const proratedTotalPrice = formatCurrency(
    +(perSeatCost * seatCount * prorationFactor).toFixed(2),
    { notation: 'standard', currency: productPrice.currency }
  )

  const perSeatPrice = formatCurrency(+perSeatCost.toFixed(2), {
    currency: productPrice.currency,
  })

  return {
    perSeatPrice,
    totalPrice,
    proratedTotalPrice,
    productPrice,
    proratedDays,
  }
}

export const getSubscriptionPriceAndInterval = (
  subscription?: Pick<WorkspaceSubscription, 'products'>
) => {
  const subscribedProduct = subscription?.products?.[0]
  const frequency = subscribedProduct?.productPrice?.frequencyUnit
  const subscriptionFrequencyDisplay =
    frequency === FrequencyUnit.Month
      ? 'monthly'
      : frequency === FrequencyUnit.Year
      ? 'annually'
      : '---'

  return {
    subscriptionPriceDisplay: getPriceDisplayMonthly(
      subscribedProduct?.productPrice
    ),
    subscriptionFrequencyDisplay,
    frequency,
  }
}

export const getProOrPlusProductPrice = (
  subscription?: Pick<WorkspaceSubscription, 'products'>
) => {
  return subscription?.products?.find(
    (p) => p?.key === 'pro' || p?.key === 'plus'
  )?.productPrice
}

export const workspaceHasGammaProProduct = (workspace?: ExistingWorkspace) => {
  return Boolean(workspace?.products?.some((product) => product === 'pro'))
}

export const workspaceHasGammaPlusProduct = (workspace?: ExistingWorkspace) => {
  return Boolean(workspace?.products?.some((product) => product === 'plus'))
}

export const getProductRequiredForFeature = (
  productFeature: ProductFeatures
): PaidProductKey | null => {
  if (ProductFeatureMap.plus.includes(productFeature)) {
    return 'plus'
  }

  if (ProductFeatureMap.pro.includes(productFeature)) {
    return 'pro'
  }

  return null
}

export const getProductForWorkspace = (
  workspace?: ExistingWorkspace
): PaidProductKey | null => {
  if (workspaceHasGammaProProduct(workspace)) {
    return 'pro'
  }

  if (workspaceHasGammaPlusProduct(workspace)) {
    return 'plus'
  }

  return null
}

export const workspaceHasPaidProduct = (workspace?: ExistingWorkspace) => {
  return Boolean(workspace?.products?.length)
}
