import {
  Avatar,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Organization } from '@gammatech/schema'
import { Trans } from '@lingui/macro'
import { memo } from 'react'

import { config } from 'config'
import { useUserContext } from 'modules/user'
import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'

type PlaceholderUser = {
  displayName?: string
  profileImageUrl?: string
  organizations: Organization[]
}

type ProfileMenuProps = {
  showRing?: boolean
}

export const ProfileMenu = memo(({ showRing }: ProfileMenuProps) => {
  const { user, anonymousUser, isUserLoading, color } = useUserContext()
  const size = useGammaBreakpointValue(['xs', showRing ? 'sm' : 'xs'])
  const placeholderUser: PlaceholderUser = {
    ...anonymousUser,
    organizations: [],
  }
  const { displayName, profileImageUrl } = user || placeholderUser

  /**
   * States:
   * NOT READY: Display disabled placeholder avatar only. No interaction.
   * READY, NO USER: Show placeholder avatar, with menu with "Login" item
   * READY, W/ USER: Show normal menu
   */
  return (
    <Menu>
      <GammaTooltip label={user ? <Trans>Profile menu</Trans> : displayName}>
        <MenuButton
          tabIndex={0}
          _focus={{
            shadow: 'outline',
          }}
          as={Avatar}
          bg={color.value}
          color={color.isDark ? 'white' : 'black'}
          border={showRing ? `solid 2px ${color.value}` : undefined}
          shadow={showRing ? 'base' : undefined}
          outline={'none'}
          name={isUserLoading ? undefined : displayName}
          src={profileImageUrl}
          size={size}
          alignItems="center"
          className="profile-menu-avatar"
          flexDirection="column"
          cursor="pointer"
          transition="box-shadow 150ms ease-out"
          _hover={{
            shadow: '0 0 0 3px #CBD5E0',
          }}
          onDragStart={(e) => e.preventDefault()}
        />
      </GammaTooltip>
      <Portal>
        <MenuList zIndex="popover">
          {/** Show the logged out experience for our PlaceholderUser  */}
          {!user ? (
            <MenuItem
              icon={
                <FontAwesomeIcon icon={regular('sign-in-alt')} fixedWidth />
              }
              onClick={() => {
                // TODO: Open popup to sign in slash sign up
                // https://github.com/gamma-app/gamma/issues/219
                window.location.href = `/signin`
              }}
            >
              <Trans>Sign In</Trans>
            </MenuItem>
          ) : (
            <>
              <MenuGroup title={user.email || displayName}>
                <MenuItem
                  color="red.500"
                  icon={
                    <FontAwesomeIcon
                      icon={regular('sign-out-alt')}
                      fixedWidth
                    />
                  }
                  onClick={() => {
                    window.location.href = `${config.API_HOST}/logout`
                  }}
                >
                  <Trans>Sign out</Trans>
                </MenuItem>
              </MenuGroup>
            </>
          )}
        </MenuList>
      </Portal>
    </Menu>
  )
})

ProfileMenu.displayName = 'ProfileMenu'
