import {
  Box,
  Center,
  chakra,
  createIcon,
  Flex,
  FlexProps,
  Text,
  useColorModeValue as mode,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { motion } from 'framer-motion'
import { Dispatch, SetStateAction } from 'react'

import { getBillingCycleDetails } from 'modules/monetization/components/UpsellModal/constants'
import { BillingCycleKey } from 'modules/monetization/types'
import { isMobileDevice } from 'utils/deviceDetection'

const ActiveIndicatorImpl = chakra('div', {
  baseStyle: {
    w: 'full',
    h: 'full',
    rounded: 'full',
    pos: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
  },
})

export const ActiveIndicator = motion(ActiveIndicatorImpl)

const CurvedLine = createIcon({
  viewBox: '0 0 38 20',
  path: (
    <path
      fill="none"
      d="M1.5 18.5H21C29.8366 18.5 37 11.3366 37 2.5V1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})

export const RadioButton = (props) => {
  const { getInputProps, getCheckboxProps, getLabelProps, state } =
    useRadio(props)

  return (
    <Box as="label" pos="relative" {...getLabelProps()}>
      <input {...getInputProps()} />
      <Center
        {...getCheckboxProps()}
        cursor="pointer"
        pos="relative"
        zIndex={1}
        h="12"
        px="8"
        textAlign="center"
        transition="all 0.2s"
        minW="8rem"
        fontWeight="medium"
        _checked={{
          color: mode('trueblue.600', 'trueblue.200'),
          fontWeight: 'bold',
        }}
      >
        {props.children}
      </Center>
      {state.isChecked && (
        <ActiveIndicator
          bg={mode('white', 'gray.600')}
          shadow="md"
          layoutId="highlight"
          transition={{
            duration: '0.1',
          }}
        />
      )}
    </Box>
  )
}

type BillingCycleSwitcherProps = FlexProps & {
  selectedBillingCycleKey: BillingCycleKey
  setSelectedBillingCycleKey: Dispatch<SetStateAction<BillingCycleKey>>
  discountPct: number
}

export const BillingCycleSwitcher = ({
  discountPct,
  selectedBillingCycleKey,
  setSelectedBillingCycleKey,
  ...rest
}: BillingCycleSwitcherProps) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: selectedBillingCycleKey,
    onChange: (e: BillingCycleKey) => {
      setSelectedBillingCycleKey(e)
    },
  })
  const isMobile = isMobileDevice()

  return (
    <Flex pos="relative" justifyContent="center" alignItems="center">
      <Flex
        display="inline-flex"
        align="center"
        bg={mode('gray.200', 'gray.700')}
        rounded="full"
        {...getRootProps(rest)}
      >
        <RadioButton
          {...getRadioProps({
            value: 'monthly',
          })}
        >
          {getBillingCycleDetails().monthly.name}
        </RadioButton>
        <RadioButton
          {...getRadioProps({
            value: 'yearly',
          })}
        >
          {getBillingCycleDetails().yearly.name}
          {discountPct > 0 ? (
            <Box
              color={mode('trueblue.600', 'trueblue.400')}
              pos="absolute"
              top={`calc(-${isMobile ? 67 : 50}% + .5rem)`}
              right={isMobile ? undefined : -24}
            >
              <Text lineHeight="1" fontWeight="bold">
                <Trans>
                  Save up to{' '}
                  {i18n.number(discountPct, {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </Trans>
              </Text>
              {!isMobile && (
                <CurvedLine
                  fontSize="2.5rem"
                  pos="relative"
                  right="8"
                  bottom="1"
                />
              )}
            </Box>
          ) : null}
        </RadioButton>
      </Flex>
    </Flex>
  )
}
