import {
  Box,
  Flex,
  Spinner,
  Text,
  useToast,
  useUpdateEffect,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
/**
 * Handy hook to show toasts in the UI if the user's connection to our servers is lost.
 * This could be due to:
 * - Disconnecting from WiFi network
 * - Inability to connect to AWS
 */
import { NextPage } from 'next'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { config } from 'config'
import { getApolloClient } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { EventEmitter } from 'utils/EventEmitter'
import { useWakeUpDetector } from 'utils/hooks'
import { sessionStore } from 'utils/storage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

// For DEBUGGING only
const DEBUG_BACKGROUND_TIME = new URLSearchParams(
  config.IS_CLIENT_SIDE ? window.location.search : ''
).get('MAX_BACKGROUND_TIME_ALLOWED')

const HEALTH_CHECK_INTERVAL = 1000 * 10

const MAX_BACKGROUND_TIME_ALLOWED =
  Number(DEBUG_BACKGROUND_TIME) || 1000 * 60 * 60 * 12 // 12 hours in ms

const reFetchApiQueries = (reason: string) => {
  return function reFetchObservableQueries() {
    console.debug(
      `%c[useApiRefetch] Refetching due to ${reason}`,
      'background-color: aqua'
    )
    getApolloClient().reFetchObservableQueries()
  }
}

const reFetchOnReconnect = reFetchApiQueries('HeartbeatConnectionState')
const reFectchOnWakeup = reFetchApiQueries('useWakeUpDetector')
const reFectchOnForeground = reFetchApiQueries('foregrounded')

const RECONNECTING_TOAST_ID = 'reconnecting-toast'
const RECONNECTING_TOAST_PATHS = ['/docs']

const ReconnectingToastComponent = () => {
  return (
    <Flex direction="column" p={4} color="white" bg="red.500" borderRadius="md">
      <Flex>
        <Spinner mr={4} />
        <Box>
          <Text lineHeight={6} fontSize="sm" fontWeight="bold">
            <Trans>Connection lost, trying to reconnect...</Trans>
          </Text>
          <Text lineHeight={6} fontSize="sm">
            <Trans>Your changes won't be saved until you're reconnected.</Trans>
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

const reconnectingToastComponentConfig = {
  render: ReconnectingToastComponent,
  duration: null,
  id: RECONNECTING_TOAST_ID,
}

export enum HeartbeatConnectionState {
  INITIALIZING = 'INITIALIZING',
  CONNECTED = 'CONNECTED',
  RECONNECTED = 'RECONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

/**
 * Refetch from the API when we reconnect or wake up
 */
const useApiRefetch = (
  connectionState: HeartbeatConnectionState,
  isBackgrounded: boolean
) => {
  const hasConnected = useRef(false)
  const shouldRefetchOnForeground = useRef(false)
  const isBackgroundedRef = useRef(isBackgrounded)
  isBackgroundedRef.current = isBackgrounded

  const isDisconnected =
    connectionState === HeartbeatConnectionState.DISCONNECTED

  const wakeupDetectorCb = useCallback(() => {
    if (isBackgroundedRef.current) {
      console.debug(
        `[useApiRefetch](${performance.now()}) BACKGROUNDED and need to refetch. Will defer`
      )
      shouldRefetchOnForeground.current = true
      return
    }
    reFectchOnWakeup()
  }, [])

  // Refetch when we wake up
  useWakeUpDetector(wakeupDetectorCb)

  // Refetch if our connection goes away then comes back
  useUpdateEffect(() => {
    if (isDisconnected) return
    // Wait for initial connection before ever calling reFetch
    if (hasConnected.current === false) {
      hasConnected.current = true
      return
    }
    if (isBackgroundedRef.current) {
      console.debug(
        `[useApiRefetch](${performance.now()}) BACKGROUNDED and need to refetch. Will defer`
      )
      shouldRefetchOnForeground.current = true
      return
    }
    reFetchOnReconnect()
  }, [isDisconnected])

  useEffect(() => {
    // Whenever we get foregrounded, check if we missed a refetch in the background
    if (!isBackgrounded && shouldRefetchOnForeground.current) {
      console.debug(
        `[useApiRefetch](${performance.now()}) FOREGROUNDED and missed a refetch. Triggering now.`
      )
      reFectchOnForeground()
    }
    shouldRefetchOnForeground.current = false
  }, [isBackgrounded])
}

const useHeartbeat = () => {
  const toast = useToast()
  const offlineEditingEnabled = useFeatureFlag('offline')
  const [heartbeatConnectionState, setHeartbeatConnectionState] =
    useState<HeartbeatConnectionState>(HeartbeatConnectionState.INITIALIZING)

  const [isBackgrounded, setBackgrounded] = useState(false)
  const [isHocuspocusConnected, setIsHocuspocusConnected] = useState<
    boolean | null
  >(null)
  const [isBrowserOnline, setIsBrowserOnline] = useState(
    typeof window !== 'undefined' ? window.navigator.onLine : false
  )

  const setHocuspocusConnected = useCallback((newState: boolean) => {
    console.debug(
      '[useHeartbeat] Hocuspocus connection state change:',
      newState
    )
    setIsHocuspocusConnected(newState)
  }, [])

  useApiRefetch(heartbeatConnectionState, isBackgrounded)

  useEffect(() => {
    // Clear the backgroundedSince value on page load
    sessionStore.removeItem(USER_SETTINGS_CONSTANTS.backgroundedSince)

    // Keep track of whether or not the window is backgrounded,
    // reloading the page if the max allowed time has elapsed
    const handler = () => {
      const documentIsHidden = document.hidden
      if (documentIsHidden) {
        // When we background, set the current time in session storage
        // This is preferred over using a setTimeout because timers are
        // less reliable when the window is backgrounded, making long
        // calculations even more inaccurate.
        sessionStore.setItem(
          USER_SETTINGS_CONSTANTS.backgroundedSince,
          new Date().toISOString()
        )
      } else {
        // We've just been foregrounded (tab is active again).
        // Check how long we were backgrounded and possibly reload the page
        const backgroundedSince = sessionStore.getItem(
          USER_SETTINGS_CONSTANTS.backgroundedSince
        )
        const backgroundedFor = backgroundedSince
          ? new Date().getTime() - new Date(backgroundedSince).getTime()
          : 0
        if (backgroundedFor > MAX_BACKGROUND_TIME_ALLOWED) {
          window.location.reload()
        }
      }
      setBackgrounded(documentIsHidden)
    }

    document.addEventListener('visibilitychange', handler)
    return () => document.removeEventListener('visibilitychange', handler)
  }, [])

  useEffect(() => {
    const onlineCallback = () => {
      setIsBrowserOnline(window.navigator.onLine)
    }

    window.addEventListener('online', onlineCallback)
    window.addEventListener('offline', onlineCallback)

    return () => {
      window.removeEventListener('online', onlineCallback)
      window.removeEventListener('offline', onlineCallback)
    }
  }, [])

  useEffect(() => {
    // Now that we're measuring the heartbeat, we'll keep track of whether
    // we've lost any of our connections
    let disconnectTimeout: NodeJS.Timeout

    const hasGoodConnection = isHocuspocusConnected !== false && isBrowserOnline
    if (!isBrowserOnline) {
      // If we know were not online, set that state immediately
      setHeartbeatConnectionState(HeartbeatConnectionState.DISCONNECTED)
    } else if (
      heartbeatConnectionState === HeartbeatConnectionState.INITIALIZING
    ) {
      // setup connection for the first time
      if (hasGoodConnection) {
        setHeartbeatConnectionState(HeartbeatConnectionState.CONNECTED)
      }
    } else if (!hasGoodConnection) {
      // Connection is bad - ensure disconnected
      // Give our connection a once cycle grace period before we consider it disconnected
      disconnectTimeout = setTimeout(() => {
        setHeartbeatConnectionState(HeartbeatConnectionState.DISCONNECTED)
      }, HEALTH_CHECK_INTERVAL)
    } else if (
      heartbeatConnectionState === HeartbeatConnectionState.DISCONNECTED
    ) {
      // We have a newly re-established connection
      setHeartbeatConnectionState(HeartbeatConnectionState.RECONNECTED)
    }

    return () => {
      // If any of our dependencies change, clear the timeout and check again.
      clearTimeout(disconnectTimeout)
    }
  }, [
    isBackgrounded,
    isHocuspocusConnected,
    isBrowserOnline,
    heartbeatConnectionState,
  ])

  useEffect(() => {
    if (
      offlineEditingEnabled ||
      !RECONNECTING_TOAST_PATHS.some((path) =>
        window.location.pathname.startsWith(path)
      )
    ) {
      return
    }
    if (heartbeatConnectionState === HeartbeatConnectionState.INITIALIZING) {
      return //no-op
    } else if (
      heartbeatConnectionState === HeartbeatConnectionState.RECONNECTED
    ) {
      if (!toast.isActive(RECONNECTING_TOAST_ID)) return
      toast.close(RECONNECTING_TOAST_ID)
      toast({
        title: <Trans>And we're back</Trans>,
        description: (
          <Trans>Reconnected. Your edits will automatically save.</Trans>
        ),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } else if (
      heartbeatConnectionState === HeartbeatConnectionState.DISCONNECTED
    ) {
      if (!toast.isActive(RECONNECTING_TOAST_ID) && !isBackgrounded) {
        toast(reconnectingToastComponentConfig)
      }
    }
  }, [heartbeatConnectionState, toast, isBackgrounded, offlineEditingEnabled])

  return { connectionState: heartbeatConnectionState, setHocuspocusConnected }
}

export type HealthCheckContextType = {
  isConnected: boolean
  setHocuspocusConnected: (isConnected: boolean | null) => void
}
export const HealthCheckContext = React.createContext<HealthCheckContextType>({
  isConnected: true,
  setHocuspocusConnected: () => {},
})

export function useHealthCheck() {
  const ctx = useContext(HealthCheckContext)
  return ctx
}

type HealthCheckContextProps = {
  children: React.ReactNode
}

export type HealthCheckEvents = {
  status: {
    isConnected: boolean
  }
}

export const HealthCheckEventEmitter = new EventEmitter<HealthCheckEvents>()

// Expose the overall connection state boolean for non-react scope consumers
let isConnected: boolean = true
export const getIsConnected = () => isConnected

export const HealthCheckContextProvider = ({
  children,
}: HealthCheckContextProps): JSX.Element => {
  const { connectionState, setHocuspocusConnected } = useHeartbeat()
  const [contextState, setContextState] = useState<HealthCheckContextType>({
    isConnected: connectionState !== HeartbeatConnectionState.DISCONNECTED,
    setHocuspocusConnected,
  })

  useEffect(() => {
    setContextState((prev) => {
      const nowConnected =
        connectionState !== HeartbeatConnectionState.DISCONNECTED
      if (prev.isConnected === nowConnected) return prev
      isConnected = nowConnected
      HealthCheckEventEmitter.emit('status', { isConnected: nowConnected })
      return { ...prev, isConnected: nowConnected }
    })
  }, [connectionState])

  return (
    <HealthCheckContext.Provider value={contextState}>
      {children}
    </HealthCheckContext.Provider>
  )
}

export function withHealthCheck<T extends Record<string, any>>(
  Component: NextPage<T>
) {
  const WithHealthCheckComponent = (props: T) => (
    <HealthCheckContextProvider>
      <Component {...props} />
    </HealthCheckContextProvider>
  )

  if ('getInitialProps' in Component) {
    WithHealthCheckComponent.getInitialProps = Component.getInitialProps
  }

  return WithHealthCheckComponent
}
