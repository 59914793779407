import { t } from '@lingui/macro'

import { colorWithOpacity, isColorDark } from 'utils/color'

import { ThemeBase } from '../types'
import { DefaultThemeBase } from './default'

export const GlassThemeBase: ThemeBase = {
  ...DefaultThemeBase,
  key: 'glass',
  name: () => t`Glass`,
  description: () => t`Transparent cards with a frosted glass effect`,
  getCSSVars(props) {
    const { cardColor } = props
    const isDark = isColorDark(cardColor)
    return {
      ...DefaultThemeBase.getCSSVars(props),
      '--card-color-transparent': colorWithOpacity(cardColor, 0.75),
      '--card-border-color': isDark
        ? 'var(--chakra-colors-whiteAlpha-300)'
        : 'var(--chakra-colors-whiteAlpha-700)',
    }
  },
  cardSx: {
    ...DefaultThemeBase.cardSx,
    backdropFilter: `blur(20px) saturate(170%)`,
    backgroundColor: 'var(--card-color-transparent)',
  },
  clickableSx: {
    ...DefaultThemeBase.clickableSx,
    backgroundColor: 'var(--card-color-transparent)',
    backdropFilter: `blur(20px) saturate(170%)`,
  },
}
