import { IconButton, useColorMode } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans, t } from '@lingui/macro'
import { Editor, NodeViewProps } from '@tiptap/core'
import { memo, MouseEvent, useCallback } from 'react'

import { SegmentEvents, useAnalytics } from 'modules/segment'
import { EditorModeEnum } from 'modules/tiptap_editor/types'
import { useManageCardMenu } from 'modules/tiptap_editor/extensions/Card/ManageCardMenu/hooks'
import { getCardUrl } from 'utils/url'

type CardMenuProps = {
  cardId: string
  docId?: string
  editor?: Editor
  shouldShowButton: boolean
  getPos: () => number | null
  editorMode: EditorModeEnum
  isTocPanelOpen: boolean
} & Pick<NodeViewProps, 'editor'>

export const CardMenu = memo(function CardMenuMemo({
  cardId,
  docId,
  getPos,
  shouldShowButton,
  editorMode,
  isTocPanelOpen,
}: CardMenuProps) {
  const cardUrl = getCardUrl({
    cardId,
    docId,
    isPresentMode: editorMode === EditorModeEnum.SLIDE_VIEW,
  })
  const analytics = useAnalytics()
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  const { openManageCardMenuClickHandler, isMenuOpen, activatorRef } =
    useManageCardMenu({
      id: 'toc',
      cardUrl,
      getPos,
    })

  const onCardMenuClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      analytics?.track(SegmentEvents.TOC_CARD_MENU_OPENED, {
        mode: editorMode,
        toc_pinned: isTocPanelOpen,
      })
      openManageCardMenuClickHandler()
    },
    [analytics, editorMode, isTocPanelOpen, openManageCardMenuClickHandler]
  )
  if (!shouldShowButton && !isMenuOpen) {
    return null
  }
  return (
    <GammaTooltip label={<Trans>Card options</Trans>} placement="top">
      <IconButton
        ref={activatorRef}
        aria-label={t`Card options`}
        onClick={onCardMenuClick}
        isRound={true}
        icon={<FontAwesomeIcon icon={regular('ellipsis-h')} />}
        size="xs"
        variant="ghost"
        _hover={{ bg: isDark ? 'whiteAlpha.100' : 'blackAlpha.100' }}
      >
        <Trans>Actions</Trans>
      </IconButton>
    </GammaTooltip>
  )
})
