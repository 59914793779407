import es from '@emoji-mart/data/i18n/es.json'
import fr from '@emoji-mart/data/i18n/fr.json'
import ja from '@emoji-mart/data/i18n/ja.json'
import kr from '@emoji-mart/data/i18n/kr.json'
import pt from '@emoji-mart/data/i18n/pt.json'
import zh from '@emoji-mart/data/i18n/zh.json'
import { i18n } from '@lingui/core'

export const gammaLocaleToEmojiMartLocaleMap = {
  'pt-br': pt,
  ja: ja,
  'zh-cn': zh,
  //   'zh-tw': zh, They don't have a set of traditional Chinese translations yet
  es: es,
  fr: fr,
  ko: kr,
}

export const getLocaleForEmojiMart = () => {
  return gammaLocaleToEmojiMartLocaleMap[i18n.locale] || undefined
}
