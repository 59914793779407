import { t } from '@lingui/macro'

import { FeatureFlags } from 'modules/featureFlags'

import { CreditCostTypes, LowCreditStatus } from './types'

export const DEFAULT_CREDIT_COSTS: FeatureFlags['aiDebits'] = {
  generateImage: 10,
  chatSuggestion: 10,
  wizardCreation: 40,
  generateV2: 40,
  generateV2WithImages: 80,
  autocomplete: 2,
}

// How many ai interactions we consider to be the threshold for calculating low credit status
export const LOW_AI_INTERACTION_THRESHOLD = 3

export const DEFAULT_CREDIT_INTERACTION: CreditCostTypes = 'wizardCreation'

export const getLowCreditAttributes = (): Record<
  LowCreditStatus,
  {
    status: 'error' | 'warning'
    lightColor: string
    darkColor: string
    description: string
  }
> => {
  return {
    [LowCreditStatus.EXHAUSTED]: {
      status: 'error',
      lightColor: 'red.600',
      darkColor: 'red.800',
      description: t`You're all out of credits`,
    },
    [LowCreditStatus.LOW]: {
      status: 'warning',
      lightColor: 'yellow.600',
      darkColor: 'yellow.800',
      description: t`You're running low on credits`,
    },
  }
}
