import { useRouter } from 'next/router'
import Script from 'next/script'

import { config } from 'config'
import { RecaptchaAction } from 'modules/api'
import { useSSRMounted } from 'utils/hooks'
import { waitFor } from 'utils/wait'

const PATHNAMES_WITH_RECAPTCHA = [
  '/signin',
  '/signup',
  '/invitations/workspaces/[workspaceId]',
]

export const ReCaptchaCSS = () => {
  const pathname = useRouter().pathname

  if (!useSSRMounted()) return null
  if (PATHNAMES_WITH_RECAPTCHA.includes(pathname)) return null

  // Hide the reCAPTCHA badge on all other pages (it can sometimes linger around in our SPA)
  // See https://cloud.google.com/recaptcha-enterprise/docs/faq#id_like_to_hide_the_badge_what_is_allowed
  return <style>{`.grecaptcha-badge { visibility: hidden; }`}</style>
}

export const ReCaptchaScript = () => {
  return (
    <Script
      src={`https://www.google.com/recaptcha/enterprise.js?render=${config.RECAPTCHA_SCORING_KEY}`}
      async
      defer
    />
  )
}

export const getRecaptchaToken = async (
  action: RecaptchaAction
): Promise<string> => {
  // Ensure grecaptcha is loaded before we try to execute it
  await waitFor(
    50,
    100
  )(() => Boolean(window['grecaptcha'])).catch(() => {
    throw new Error('Timed out waiting for grecaptcha')
  })

  return new Promise((resolve, reject) => {
    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          config.RECAPTCHA_SCORING_KEY,
          {
            action,
          }
        )

        // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
        // reCAPTCHA Enterprise to the end user's browser.
        // This token must be validated by creating an assessment.
        // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment

        resolve(token)
      } catch (error) {
        console.log(error)

        reject(error)
      }
    })
  })
}
