import { t } from '@lingui/macro'

import {
  brightenColor,
  colorWithOpacity,
  isColorDark,
  colorWithLightness,
} from 'utils/color'
import { isMobileDevice } from 'utils/deviceDetection'

import { resetHeadingStylesheet } from '../styles/heading'
import { ThemeBase } from '../types'

const BLOCK_BORDER_RADIUS = '0.375em'

export const DefaultThemeBase: ThemeBase = {
  key: 'default',
  name: () => t`Default`,
  description: () => t`Standard cards with rounded corners and subtle shadows`,
  getCSSVars({ cardColor, accentColor, boxColor }) {
    const backgroundColor = boxColor ?? cardColor
    const isDark = isColorDark(backgroundColor)
    const smartLayoutBackgroundColor =
      boxColor ??
      (isDark
        ? colorWithLightness(accentColor, 0.25)
        : colorWithLightness(accentColor, 0.9))
    const smartLayoutBorderColor = isDark
      ? colorWithLightness(smartLayoutBackgroundColor, 0.3)
      : colorWithLightness(smartLayoutBackgroundColor, 0.8)

    return {
      '--grid-outline-color': isDark
        ? 'var(--chakra-colors-gray-700)'
        : 'var(--chakra-colors-gray-200)',
      // chakra's md shadow, sized in ems
      '--md-shadow':
        '0 0.25em 0.375em -0.0625em rgba(0, 0, 0, 0.1),0 0.125em 0.25em -0.0625em rgba(0, 0, 0, 0.06)',
      '--line-thickness': '0.2em',
      // Use a shadow for the border so it always goes outside the card and isn't covered by accent items
      '--card-border-color': isDark
        ? 'var(--chakra-colors-gray-700)'
        : 'var(--chakra-colors-gray-200)',
      '--card-border': `0.0625em solid var(--card-border-color)`,
      '--card-border-shadow': `0 0 0 0.0625em var(--card-border-color)`,
      '--clickable-background-color': colorWithOpacity(
        isDark ? brightenColor(cardColor, 20) : brightenColor(cardColor, -5),
        0.6
      ),
      '--box-border-radius': '0.375em',
      '--smart-layout-background-color': smartLayoutBackgroundColor,
      '--smart-layout-border-color': smartLayoutBorderColor,
      '--smart-layout-background-image': boxColor
        ? 'none'
        : 'var(--accent-gradient-background)',
    }
  },
  boxSx: {
    border: '0.0625em solid var(--box-border-color)',
    borderRadius: 'var(--box-border-radius)',
  },
  clickableSx: {
    boxShadow: 'var(--md-shadow)',
    backgroundColor: 'var(--clickable-background-color)',
    '@media print': {
      boxShadow: 'none',
    },
  },
  cardSx: {
    boxShadow: 'var(--md-shadow), var(--card-border-shadow)',
    borderRadius: isMobileDevice() ? '0.375em' : '0.75em',
    backgroundColor: 'var(--card-color)',
    '@media print': {
      boxShadow: 'none',
      border: 'var(--card-border)',
    },
  },
  imageSx: {
    borderRadius: BLOCK_BORDER_RADIUS,
  },
  smartLayoutBoxSx: {
    borderRadius: BLOCK_BORDER_RADIUS,
    backgroundColor: 'var(--smart-layout-background-color)',
    backgroundImage: 'var(--smart-layout-background-image)',
    border: '0.0625em solid var(--smart-layout-border-color)',
    // Reset headings to match body color to avoid visual noise
    ...resetHeadingStylesheet('var(--body-color)'),
  },
  smartLayoutContentSx: {
    // Reset headings to match body color to avoid visual noise
    ...resetHeadingStylesheet('var(--body-color)'),
  },
  smartLayoutLineSx: {
    backgroundColor: 'var(--smart-layout-border-color)',
    borderRadius: BLOCK_BORDER_RADIUS,
  },
  cssAccentSx: {
    backgroundImage: 'var(--accent-gradient)',
    backgroundColor: 'var(--link-color)',
  },
  svgAccentSx: {
    // Use the accent gradient if there is one, otherwise fall back to the accent color
    fill: `url(#theme-accent-gradient-linear) var(--link-color)`,
  },
  svgShapeSx: {
    fill: 'url(#theme-accent-background-gradient-linear) var(--smart-layout-background-color)',
    stroke: 'var(--smart-layout-border-color)',
    strokeWidth: '0.0625em',
    vectorEffect: 'non-scaling-stroke',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
  },
  tableSx: {
    border: '0.0625em solid var(--box-border-color)',
    borderRadius: 'var(--box-border-radius)',
    'tbody tr': {
      _even: {
        backgroundColor: 'blackAlpha.50',
      },
      _odd: {
        backgroundColor: 'whiteAlpha.50',
      },
    },

    td: {
      borderLeft: '0.0625em solid var(--box-border-color)',
      _first: {
        borderLeftWidth: '0',
      },
    },
  },
}
