import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { ReactNode } from 'react'

import { useLinguiInit } from 'modules/i18n/hooks/useLinguiInit'

export const I18nProviderWrapper = ({ children }: { children: ReactNode }) => {
  useLinguiInit()
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
