import { t } from '@lingui/macro'

import { brightenColor, isColorDark, saturateColor } from 'utils/color'
import { isMobileDevice } from 'utils/deviceDetection'

import { ThemeBase } from '../types'
import { DefaultThemeBase } from './default'

export const FinesseThemeBase: ThemeBase = {
  ...DefaultThemeBase,
  key: 'finesse',
  name: () => t`Finesse`,
  description: () => t`Minimalist, flat design with playful details`,
  getCSSVars(props) {
    const { boxColor, cardColor } = props
    const isDark = isColorDark(cardColor)
    return {
      ...DefaultThemeBase.getCSSVars(props),
      '--box-background-color': saturateColor(
        brightenColor(boxColor ?? cardColor, isDark ? 5 : -5),
        isDark ? 5 : 20
      ),
      '--line-thickness': '0.125em',
      '--box-border-radius': '0.25em',
    }
  },
  cardSx: {
    borderRadius: 'var(--box-border-radius)',
    backgroundColor: 'var(--card-color)',
  },
  boxSx: {
    border: '0px',
    borderRadius: 'var(--box-border-radius)',
  },
  clickableSx: {
    border: '0.0625em solid var(--accent-color-border)',
    backgroundColor: 'var(--box-background-color)',
  },
  smartLayoutBoxSx: {
    borderRadius: 'var(--box-border-radius)',
    backgroundColor: 'var(--box-background-color)',
  },
  smartLayoutLineSx: {
    opacity: 0.8,
    backgroundColor: 'var(--accent-color)',
  },
  svgShapeSx: {
    fill: 'var(--box-background-color)',
  },
  smartLayoutContentSx: {},
  tableSx: {
    'tbody tr': {
      _even: {
        backgroundColor: 'var(--box-background-color)',
      },
      td: {
        borderX: '0px',
      },
      position: 'relative',
      _after: {
        content: '""',
        position: 'absolute',
        right: isMobileDevice() ? 0 : '0.25em',
        top: isMobileDevice() ? 0 : '0.25em',
        width: '100%',
        height: '100%',
        border: '0.125em solid var(--accent-color)',
        borderTopWidth: '0px',
        zIndex: 1,
        borderTopRadius: 'inherit',
        borderBottomRadius: 'inherit',
        opacity: 0.8,
        pointerEvents: 'none',
      },
      _first: {
        _after: {
          borderTopWidth: '0.125em',
        },
        borderTopRadius: '0.25em',
      },
      _last: {
        borderBottomRadius: '0.25em',
      },
    },
  },
}
