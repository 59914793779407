import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

import { APPROX_TOKENS_PER_WORD } from 'modules/ai/tokens'
import { DocGenerateInput } from 'modules/api'

import { GeneratorInput, TextAmount } from './types'

export const DEFAULT_NUM_CARDS = 8

export const GENERATE_SETTING_DEFAULTS: DocGenerateInput['settings'] = {
  numCards: 8,
  locale: 'en',
  editorMode: 'freeform',

  imageProvider: 'web',
  imageLicense: 'All',

  audience: '',
  tone: '',
  textAmount: 'md',
  textMode: 'generate',
}

export const GENERATE_INPUT_DEFAULTS: GeneratorInput = {
  id: '',

  interactionId: '',
  status: 'draft',
  docGenerationId: '',

  content: '',
  prompt: '',
  format: 'deck',

  settings: GENERATE_SETTING_DEFAULTS,
}

// account for things like <p> tags, <ul> tags, etc
export const TOKEN_PER_CARD_BUFFER = 75

export const TEXT_AMOUNTS: Record<
  TextAmount,
  {
    label: string
    description: string
    icon: FontAwesomeIconProps['icon']
    tokensPerOutputCard: number
  }
> = {
  sm: {
    label: 'Brief',
    icon: regular('dash'),
    description: 'Short bullets, best for a live presentation',
    tokensPerOutputCard:
      Math.floor(30 * APPROX_TOKENS_PER_WORD) + TOKEN_PER_CARD_BUFFER,
  },
  md: {
    label: 'Medium',
    icon: regular('bars-sort'),
    description: 'Concise paragraphs, for sharing or presenting',
    tokensPerOutputCard:
      Math.floor(60 * APPROX_TOKENS_PER_WORD) + TOKEN_PER_CARD_BUFFER,
  },
  lg: {
    label: 'Detailed',
    icon: regular('align-left'),
    description: 'Detailed paragraphs, best for a longer document',
    tokensPerOutputCard:
      Math.floor(100 * APPROX_TOKENS_PER_WORD) + TOKEN_PER_CARD_BUFFER,
  },
}
