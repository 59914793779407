import { getUnixTime } from 'date-fns'

import { config } from 'config'
import { Organization } from 'modules/api/generated/graphql'
import { fetchHMAC } from 'modules/auth/utils'
import { featureFlags } from 'modules/featureFlags'

import { GraphqlUser } from '../user/context/UserContext'

const boot = () => {
  const intercom = window.Intercom
  if (intercom) {
    intercom('boot', {
      app_id: config.INTERCOM_APP_ID,
      hide_default_launcher: true,
    })
  }
}

export const init = () => {
  let isBooted = false

  featureFlags.initializePromise.then(() => {
    if (featureFlags.get('intercom')) {
      boot()
      isBooted = true
    }

    featureFlags.subscribe('intercom', (isEnabled) => {
      if (isEnabled && !isBooted) {
        boot()
        isBooted = true
      }
    })
  })
}

export const updateIntercomUser = async ({
  user,
  currentWorkspace,
}: {
  user: GraphqlUser
  currentWorkspace?: Organization
}) => {
  const [user_hash] = await Promise.all([
    fetchHMAC('intercom'),
    featureFlags.initializePromise,
  ])

  const intercom = window.Intercom

  if (intercom && featureFlags.get('intercom')) {
    intercom('update', {
      email: user?.email,
      user_id: user?.id,
      user_hash,
      name: user?.displayName,
      jobFunction: user?.jobFunction,
      created_at: user.createdTime ? getUnixTime(user.createdTime) : undefined,
      ...(user?.profileImageUrl
        ? {
            avatar: {
              type: 'avatar',
              image_url: user?.profileImageUrl,
            },
          }
        : {}),
      company: {
        company_id: currentWorkspace?.id,
        name: currentWorkspace?.name || 'unknown company',
        plan: currentWorkspace?.products?.join(','),
        // TODO: Add monthly_spend here
        // https://linear.app/gamma-app/issue/G-4822/add-monthly-spend-to-intercom-user-metadata
      },
    })
  }
}
