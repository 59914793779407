import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  VStack,
  StackProps,
  ContainerProps,
  HeadingProps,
} from '@chakra-ui/react'
import React from 'react'

import { Logo } from 'gamma_components'
import CenteredMessageBg from 'publicImages/centered-message-bg-flipped.png'

import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../constants'

type CenteredMessageLayoutProps = {
  children: React.ReactNode
  showFooter: boolean
}
const blurCardStyle = {
  border: '1px solid #e7dfd7',
  borderRadius: '16px',
  backgroundColor: 'hsla(0, 0%, 100%, 0.95)',
  shadow: '0 20px 30px 0 rgb(211 187 162 / 64%)',
  backdropFilter: 'blur(10px)',
}

const fancyGradientTextStyle = {
  position: 'relative',
  fontWeight: '500',
  fontFamily: 'p22-mackinac-pro, sans-serif',
  lineHeight: '1.2em',
  letterSpacing: '-0.03em',
  backgroundImage:
    'linear-gradient(50deg, #2b0aff, #ff5b8a 49%, #ff5b8a 53%, #ff5b8a 55%, #fba64b 77%, #f99b52)',
  backgroundClip: 'text',
  color: 'transparent',
}

export const CenteredMessagePage = ({
  children,
  showFooter,
  ...props
}: CenteredMessageLayoutProps) => {
  return (
    <Flex
      minWidth="100vw"
      minHeight="100vh"
      align="top"
      backgroundColor="linen.300"
      direction="column"
      p={[2, 6, 12]}
      px={[0, 4, 12]}
      backgroundImage={`${CenteredMessageBg.src}?w=${CenteredMessageBg.width}`}
      backgroundPosition="0% -5%"
      backgroundRepeat="no-repeat"
      backgroundSize="100%"
      {...props}
    >
      <Flex direction="column" flex="1">
        <Flex flex="1" align="center" justify="center" direction="column">
          {children}
        </Flex>
        {showFooter && <CenteredMessagePageFooter />}
      </Flex>
    </Flex>
  )
}

export const CenteredMessageCard = ({
  lede,
  title,
  children,
  maxW = '60em',
  minH,
  stackProps = {},
  titleProps = {},
}: {
  lede?: React.ReactNode | string
  title: React.ReactNode | string
  children: React.ReactNode
  maxW?: ContainerProps['maxW']
  minH?: ContainerProps['minH']
  stackProps?: StackProps
  titleProps?: HeadingProps
}) => {
  return (
    <Container maxW={maxW} minH={minH}>
      <Stack {...blurCardStyle} p={[8, 12]} spacing={4} {...stackProps}>
        {lede &&
          (typeof lede === 'string' ? (
            <Heading size="md">{lede}</Heading>
          ) : (
            lede
          ))}
        <Heading
          sx={fancyGradientTextStyle}
          fontSize={['32px', '48px']}
          {...titleProps}
        >
          {title}
        </Heading>
        <Box fontSize={['lg', 'xl']} w="100%">
          {children}
        </Box>
      </Stack>
    </Container>
  )
}

export const CenteredMessageCardFooter = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <Container fontSize="sm" color="gray.600">
      <Stack mt={12}>
        <Divider borderColor="gray.300" mb={4} />
        <Center>{children}</Center>
      </Stack>
    </Container>
  )
}

const CenteredMessagePageFooter = () => (
  <VStack mt={12} mb={4}>
    <Box w="35px">{Logo}</Box>
    <Text color="gray.500" fontSize="xs" fontWeight="500">
      &copy; {new Date().getFullYear()} Gamma Tech, Inc.
    </Text>
    <Text
      color="gray.300"
      fontSize="xs"
      sx={{
        a: {
          color: 'gray.400',
          _hover: { textDecoration: 'underline' },
        },
      }}
    >
      <Link href={TERMS_OF_SERVICE_URL}>Terms of Service</Link> &bull;{' '}
      <Link href={PRIVACY_POLICY_URL}>Privacy Policy</Link>
    </Text>
  </VStack>
)
