import { i18n, Messages } from '@lingui/core'

import { DEFAULT_GAMMA_LOCALE, PSEUDO_LOCALE_KEY } from './constants'

export async function dynamicLoadAndActivate(locale: string) {
  let localeToUse = locale
  if (!locale) {
    console.warn('[i18n] Unexpected empty locale')
    localeToUse = DEFAULT_GAMMA_LOCALE
  }
  try {
    let catalog: Messages

    if (localeToUse === PSEUDO_LOCALE_KEY) {
      catalog = await import(`translations/${localeToUse}/messages`)
    } else {
      catalog = await import(
        `@lingui/loader!/src/translations/${localeToUse}/messages.po`
      )
    }
    i18n.load(localeToUse, catalog.messages)
    i18n.activate(localeToUse)
  } catch (e) {
    console.error('[i18n] Error loading locale messages:', e.message)
  }
}
