import { useCallback, useEffect, useRef, useState } from 'react'

import { config } from 'config'
import { useAppDispatch, useAppSelector, useAppStore } from 'modules/redux'
import { selectDoc } from 'modules/tiptap_editor/reducer'

import { ManageCardMenuOptions } from './ManageCardMenu'
import { showManageCardMenu, updateManageCardMenuOptions } from './reducer'

export const useManageCardMenu = (options: ManageCardMenuOptions) => {
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const activatorRef = useRef<HTMLButtonElement | null>(null)
  const { getPos, cardUrl } = options

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const onClose = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  useEffect(() => {
    if (isMenuOpen) {
      dispatch(updateManageCardMenuOptions({ getPos, cardUrl }))
    }
  }, [getPos, dispatch, isMenuOpen, cardUrl])

  const openManageCardMenu = useCallback(() => {
    setIsMenuOpen(true)
    dispatch(
      showManageCardMenu({ ...options, onClose, ref: activatorRef.current! })
    )
  }, [dispatch, options, onClose])

  const openManageCardMenuClickHandler = useCallback(() => {
    const lastRef = store.getState().ManageCardMenu.lastRef
    // since the clickOutside handler will occur synchronously before
    // this click handler.  If the last activator ref was this button
    // don't open the menu, instead let it "toggle" by allowing the
    // clickOutside handler to close the menu
    if (lastRef && lastRef === activatorRef.current) {
      return
    }
    openManageCardMenu()
  }, [openManageCardMenu, store])

  return {
    isMenuOpen,
    openManageCardMenuClickHandler,
    activatorRef,
  }
}

export const useIsCardLinkSharingOpen = (): boolean => {
  const doc = useAppSelector(selectDoc)
  if (!doc) {
    return false
  }
  const shareToken = config.SHARE_TOKEN
  const isPublic = doc.publicAccess !== null
  const hasEmptyShareToken = doc.accessLinks?.some((l) => l.token === '')
  const accessLinkIsOff = doc.accessLinks?.some((l) => l.permission === null)
  if (isPublic || shareToken || (hasEmptyShareToken && !accessLinkIsOff)) {
    return true
  }
  return false
}
