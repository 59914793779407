import { Box } from '@chakra-ui/react'

import {
  BackgroundOptions,
  BackgroundType,
  MaskOptions,
} from 'modules/tiptap_editor/styles/types'

type BackgroundMaskProps = {
  background: BackgroundOptions
  isDark?: boolean
}

export const DEFAULT_MASK: MaskOptions = {
  effect: 'faded',
}

export const BackgroundMask = ({ background }: BackgroundMaskProps) => {
  const { mask = DEFAULT_MASK } = background
  if (!backgroundSupportsMask(background) || !isMaskActive(mask)) {
    return null
  }
  const maskProps = {
    frosted: {
      backdropFilter: 'blur(12px) saturate(150%)',
      backgroundColor: 'var(--card-color-mask)',
    },
    faded: {
      backgroundColor: 'var(--card-color-mask)',
    },
  }[mask.effect || 'none']

  return (
    <Box
      position="absolute"
      inset="0"
      borderRadius="inherit"
      transitionProperty="backdrop-filter, background"
      transitionDuration="normal"
      {...maskProps}
    />
  )
}

export const backgroundSupportsMask = (background: BackgroundOptions) => {
  return background.type === BackgroundType.IMAGE || !background.type // Placeholder
}

export const isMaskActive = (mask?: MaskOptions): mask is MaskOptions => {
  return !!mask && mask.effect !== 'none'
}
