import {
  Editor,
  findChildren,
  findParentNodeClosestToPos,
  NodeWithPos,
} from '@tiptap/core'
import { Node, Node as ProsemirrorNode, ResolvedPos } from 'prosemirror-model'
import { Selection } from 'prosemirror-state'

import { findDirectChildren, findParentNodes } from '../../utils'
import { LAYOUT_PRESETS } from './CardLayout/layoutPresets'
import { CARD_NODE_NAME, CARD_WIDTHS } from './constants'
import { CardLayout } from './types'

export const isCardNode = (node: ProsemirrorNode) =>
  node.type.name === CARD_NODE_NAME

export const isCardLayoutItemNode = (node: ProsemirrorNode) =>
  node.type.name === 'cardLayoutItem' ||
  node.type.name === 'cardAccentLayoutItem'

export const getCardLayoutItemChildren = (node: Node): Node[] => {
  return findDirectChildren(node, isCardLayoutItemNode).map((n) => n.node)
}

export const isNodeFirstCard = (
  doc: ProsemirrorNode,
  node: ProsemirrorNode
) => {
  const firstCard = doc.firstChild!.firstChild!
  return firstCard.type.name === 'card' && firstCard.attrs.id === node.attrs.id
}

export const findCardById = (editor: Editor, cardId?: string | null) => {
  if (!cardId) return null
  const result = findChildren(
    editor.state.doc,
    (n) => isCardNode(n) && n.attrs.id === cardId
  )
  return result.length ? result[0] : null
}

export const findCardsById = (editor: Editor, ids: string[]): Node[] => {
  if (!ids.length) return []
  const cards: Node[] = []
  editor.state.doc.descendants((node) => {
    if (isCardNode(node) && ids.includes(node.attrs.id)) {
      cards.push(node)
    }
  })
  return cards
}

// In present mode, the first child of the scroll container is needed to compute the height
export const getFullHeightCardNode = (rootNode: HTMLElement | null) => {
  return rootNode
    ? rootNode.querySelector<HTMLElement>('[data-card-id] > div')
    : null
}

// Find the card node nearest the pos (inclusive of pos)
// The isCollapsed filter is optional
export const findCardNodeClosestToPos = (editor: Editor, pos: number) => {
  const nodeAtPos = editor.state.doc.nodeAt(pos)
  const $pos = editor.state.doc.resolve(pos)
  return nodeAtPos && isCardNode(nodeAtPos)
    ? { node: nodeAtPos, pos, start: $pos.start, depth: $pos.depth }
    : findParentNodeClosestToPos(editor.state.doc.resolve(pos), isCardNode)
}

export const getClosestParentContainerOption = (parents: Node[], key: string) =>
  parents.find((n) => n.attrs.container[key] !== undefined)?.attrs.container[
    key
  ]

export const findTopLevelCardsWithPos = (
  doc: ProsemirrorNode
): NodeWithPos[] | null => {
  const document = doc.firstChild
  if (!document) {
    return null
  }
  const allCards = findDirectChildren(document, (n) => {
    return isCardNode(n)
  }).map((c) => {
    return {
      ...c,
      pos: c.pos + 1, // map wrt doc pos
    }
  })
  return allCards
}

export const isParentCardDark = (selection: Selection) => {
  const parentCards = findParentNodes(selection.$from, isCardNode).map(
    (c) => c.node
  )
  return getClosestParentContainerOption(parentCards, 'isDark')
}

export const getParentCardWidthPxFromSelection = (selection: Selection) => {
  const parentCards = findParentNodes(selection.$from, isCardNode).map(
    (c) => c.node
  )
  const width = getClosestParentContainerOption(parentCards, 'width')
  return CARD_WIDTHS[width || 'md'] * 16
}

export const canMergeCardAbove = ($card: ResolvedPos) => {
  if (!$card.nodeAfter) {
    return false
  }

  const cardLayoutItems = getCardLayoutItemChildren($card.nodeAfter)
  if (cardLayoutItems.length > 1) {
    // can't split with a card layout with more than one item
    return false
  }

  return true
}

export const isValidCardLayout = (
  maybeLayout: string
): maybeLayout is CardLayout => {
  return LAYOUT_PRESETS[maybeLayout] !== undefined
}
