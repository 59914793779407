import { TransformImageOptions } from 'modules/ai/transform/types'
import {
  BackgroundOptions,
  ContainerWidth,
} from 'modules/tiptap_editor/styles/types'

import { DEFAULT_DOC_BACKGROUND } from '../../../styles/constants'
import { CardDimensionsKey } from '../../Card/CardDimensions'
import { CardFullBleed } from '../../Card/types'
import { DocFlags } from '../../DocFlags/docFlags'
import { DocFormat } from './DocFormats'

export type DocSettings = {
  cardDimensions?: CardDimensionsKey
  defaultFullBleed?: Exclude<CardFullBleed, 'default'>
  defaultContentWidth?: ContainerWidth
  presentBackdrop?: boolean
  metaDescription?: string
}

export type DocGenerateStatus =
  | null // represents not a ai generated doc
  | 'streaming'
  | 'done'

export type DocGenerateInfo = {
  interactionId?: string
  streamId?: string
  lastEventId?: string
  lastCompletedCardId?: string | null
  lastCompletedCardIndex?: number | null
  imageOptions?: TransformImageOptions
}

export interface DocumentAttributes {
  docId: string | null
  background: BackgroundOptions
  format: DocFormat['key'] | null
  docFlags: Partial<DocFlags>
  customCode: {
    bodyStart?: string
  }
  settings: DocSettings
  generateStatus: DocGenerateStatus
  generateInfo: DocGenerateInfo
}

export const defaultAttrs = {
  docId: null,
  background: DEFAULT_DOC_BACKGROUND,
  format: null,
  docFlags: {},
  customCode: {},
  settings: {},
  generateStatus: null,
  generateInfo: {},
} as const
