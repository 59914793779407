import { Box, useColorMode } from '@chakra-ui/react'
import type { Picker } from 'emoji-mart'
import React, { useEffect, useMemo, useRef } from 'react'

import { getLocaleForEmojiMart } from 'modules/i18n/utils/emoji'
import { localStore } from 'utils/storage'

import { emojiMartPromise } from '../emojiMartPromise'
import { PickerProps } from '../types'

let EmojiMartPicker: Picker
emojiMartPromise.then((module) => {
  EmojiMartPicker = module.Picker
})

// Yanked and modified from https://github.com/missive/emoji-mart/blob/052badf03695a16390188efcc4b115ccb3346b13/packages/emoji-mart-react/react.js
// Modified to wait for emojiMartPromise to resolve so we can set the Picker
const EmojiPickerCore = (
  props: PickerProps & {
    onCreate?: (picker: Picker) => void
    dataTargetName?: string
  }
) => {
  const ref = useRef<HTMLDivElement>(null)
  const instance = useRef<any>(null)

  const dataTargetNameProp = props.dataTargetName
    ? { 'data-target-name': props.dataTargetName }
    : {}
  if (instance.current) {
    instance.current.update(props)
  }

  useEffect(() => {
    emojiMartPromise.then(() => {
      instance.current = new EmojiMartPicker({ ...props, ref })
      props.onCreate?.(instance.current)
    })

    return () => {
      instance.current = null
    }
  }, [])

  return <Box ref={ref} {...dataTargetNameProp} />
}

const DEFAULT_FREQUENTS = [
  '+1',
  '-1',
  'heart_eyes',
  'thinking_face',
  'question',
  'open_mouth',
  'white_check_mark',
  'x',
  'tada',
]

type EmojiPickerProps = {
  onCreate?: (picker: Picker) => void
  searchPosition?: 'sticky' | 'static' | 'none'
  perLine?: number
  emojiSize?: number
  emojiButtonSize?: number
  handlePick(emoji: any): void
  shouldFocusSearch?: boolean
  dataTargetName?: string
}

export const EmojiPicker: React.FC<EmojiPickerProps> = ({
  handlePick,
  shouldFocusSearch = true,
  perLine = 12,
  dataTargetName,
  ...rest
}) => {
  const { colorMode } = useColorMode()

  useEffect(() => {
    // If they don't already have frequently used emoji, prefill with our suggested ones
    const frequents = localStore.getItem('emoji-mart.frequently')
    if (!frequents) {
      // Seed it so our top one has the most uses, followed by previous with -1, etc.
      const defaultFreq = Object.fromEntries(
        DEFAULT_FREQUENTS.map((id, idx) => [id, DEFAULT_FREQUENTS.length - idx])
      )
      localStore.setItem('emoji-mart.frequently', JSON.stringify(defaultFreq))
    }
  }, [])
  const locale = useMemo(() => getLocaleForEmojiMart(), [])

  if (!EmojiMartPicker) {
    console.warn("[EmojiPicker] Emoji picker hasn't loaded yet")
    return null
  }

  return (
    <EmojiPickerCore
      perLine={perLine}
      set="native"
      theme={colorMode === 'dark' ? 'dark' : 'light'}
      previewPosition="none"
      skinTonePosition="none"
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={shouldFocusSearch}
      onEmojiSelect={(emoji) => {
        handlePick(emoji)
      }}
      maxFrequentRows={2}
      dataTargetName={dataTargetName}
      i18n={locale}
      {...rest}
    />
  )
}
