import { t } from '@lingui/macro'

import { brightenColor, colorWithOpacity, isColorDark } from 'utils/color'

import { ThemeBase } from '../types'

export const DaydreamThemeBase: ThemeBase = {
  key: 'daydream',
  name: () => t`Daydream`,
  description: () => t`Big rounded edges, bold colors, and thick lines`,
  getCSSVars({ cardColor, accentPalette }) {
    const isDark = isColorDark(cardColor)
    const borderColor = brightenColor(cardColor, isDark ? 15 : -8)
    const rowColors = Object.fromEntries(
      accentPalette.map((color, i) => {
        const selector = `:nth-child(${accentPalette.length}n + ${i + 1})`
        const value = {
          '--item-color': color,
        }
        return [selector, value]
      })
    )

    return {
      '--line-color': borderColor,
      '--card-border-shadow': `0 0 0 0.25em ${borderColor}`,
      '--card-border-print': `0.25em solid ${borderColor}`,
      '--line-thickness': '0.125em',
      '--card-color-transparent': colorWithOpacity(cardColor, 0.75),
      '--box-border-radius': '1.5em',
      '.node-tableRow, .node-smartLayoutCell': rowColors,
    }
  },
  hasTransparentSmartLayoutBoxes: true,
  cardSx: {
    boxShadow: 'var(--card-border-shadow)',
    '@media print': {
      boxShadow: 'none',
      border: 'var(--card-border-print)',
    },
    borderRadius: `2em`,
    backgroundColor: 'var(--card-color-transparent)',
  },
  boxSx: {
    backgroundColor: 'var(--card-color)',
    borderRadius: `var(--box-border-radius)`,
  },
  imageSx: {
    borderRadius: `2.5em`,
  },
  smartLayoutCellSx: {
    '--heading-color': 'var(--item-color)',
    '--line-color': 'var(--item-color)',
  },
  smartLayoutBoxSx: {
    boxShadow: `0 0 0 var(--line-thickness) var(--item-color)`,
    '@media print': {
      boxShadow: 'none',
      border: 'var(--line-thickness) solid var(--item-color)',
    },
    backgroundColor: 'var(--card-color)',
    borderRadius: 'var(--box-border-radius)',
  },
  smartLayoutContentSx: {},
  smartLayoutImageSx: {
    boxShadow: `0 0 0 var(--line-thickness) var(--item-color)`,
    borderRadius: 'var(--box-border-radius)',
    '@media print': {
      boxShadow: 'none',
      border: 'var(--line-thickness) solid var(--item-color)',
    },
  },
  smartLayoutImageShape: 'circle',
  smartLayoutLineSx: {
    backgroundColor: 'var(--line-color)',
  },
  svgAccentSx: {
    fill: 'var(--item-color)',
  },
  cssAccentSx: {
    backgroundColor: 'var(--item-color)',
  },
  svgShapeSx: {
    stroke: 'var(--item-color)',
    fill: 'transparent',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 'calc(var(--line-thickness) * 0.75)',
    vectorEffect: 'non-scaling-stroke',
  },
  clickableSx: {
    // For embeds, a shadow works better to avoid a gap between images and the border caused by the large border radius
    boxShadow: `0 0 0 var(--line-thickness) var(--link-color)`,
    '@media print': {
      boxShadow: 'none',
      border: 'var(--line-thickness) solid var(--link-color)',
    },
    borderRadius: `1.5em`,
    // On buttons, the shadow causes a double border, so we use a border instead
    '&.button': {
      border: 'var(--line-thickness) solid var(--link-color)',
      boxShadow: 'none',
    },
  },
  tableSx: {
    backgroundColor: 'var(--card-color)',
    borderRadius: `1.5em`,
    boxShadow: 'var(--card-border-shadow)',
    '@media print': {
      boxShadow: 'none',
      border: 'var(--card-border-print)',
    },
    'tbody tr': {
      ':last-child': { borderBottom: 'none !important' },
      borderBottom: '0.125em solid var(--item-color)',
    },
  },
}
