import { slugify } from '@gammatech/lib/dist/slugify'

import { config } from 'config'
import { DocGeneration, ExistingWorkspace, GenerationType } from 'modules/api'

// See packages/server/src/common/utils.ts
export const DOC_NANOID_REGEX = /[0-9a-z]{15}$/

export const CARD_HASH_PREFIX = 'card-'

export const generateInviteUrl = (workspace: ExistingWorkspace) => {
  return `${config.FRONTEND_URL}/${workspace.id}/join?code=${workspace.inviteCode}`
}

export const generateReferralLink = ({
  userId,
  workspace,
}: {
  userId: string
  workspace?: ExistingWorkspace
}) => {
  const baseUrl = workspace
    ? new URL(generateInviteUrl(workspace))
    : new URL(`${config.FRONTEND_URL}/signup`)
  baseUrl.searchParams.set('r', userId)
  return baseUrl.href
}

export const generateDocUrl = ({
  docId,
  path = 'docs',
  docTitle = '',
  token = undefined,
  absolute = false,
}: {
  docId?: string
  path?: 'docs' | 'example' | 'public' | 'embed'
  docTitle?: string
  token?: string
  absolute?: boolean
}) => {
  return `${!absolute ? '' : config.FRONTEND_URL}/${path}/${
    docTitle ? `${slugify(docTitle)}-` : ''
  }${docId}${token ? `?token=${token}` : ''}`
}

export const generateChannelUrl = (
  { id, slug }: { id: string; slug?: string },
  relative = true
) => {
  return `${relative ? '' : config.FRONTEND_URL}/folders/${
    slug === id ? '' : slug
  }-${id}`
}

export const generateExampleUrl = ({ docId }, relative = true) => {
  return `${relative ? '' : config.FRONTEND_URL}/example/${docId}`
}

export const generateFontsUrl = () => {
  return `${config.FRONTEND_URL}/#fonts`
}

export const generateEmbedCode = ({
  docId,
  docTitle,
}: {
  docId: string
  docTitle?: string
}) => {
  const embedUrl = generateDocUrl({ docId, path: 'embed', absolute: true })
  return `<iframe src="${embedUrl}" style="width: 700px; max-width: 100%; height: 450px" allow="fullscreen" title="${
    docTitle ?? 'Gamma embed'
  }"></iframe>`
}

export const getCardHash = (cardId: string) => {
  return `#${CARD_HASH_PREFIX}${cardId}`
}

export const getCardUrl = ({
  cardId,
  docId,
  isPresentMode = false,
}: {
  cardId: string
  docId?: string
  isPresentMode?: boolean
}) => {
  if (!docId) {
    console.error('[getCardUrl] docId is undefined. Returning empty string')
    return ''
  }
  const currentPath =
    typeof window !== 'undefined' && window.location.pathname.split('/')[1]
  const shouldUseCurrentPath =
    currentPath && ['docs', 'public', 'example'].includes(currentPath)
  const isEmbedUrl = currentPath === 'embed'
  const url = new URL(
    `${config.FRONTEND_URL}/${
      shouldUseCurrentPath ? currentPath : isEmbedUrl ? 'public' : 'docs'
    }/${docId}`
  )
  if (isPresentMode) {
    url.searchParams.set('mode', 'present')
  }
  if (config.SHARE_TOKEN) {
    url.searchParams.set('token', config.SHARE_TOKEN)
  }
  url.hash = getCardHash(cardId)
  return url.toString()
}

export const generateCommentUrl = ({ docId, id }) => {
  return `${config.FRONTEND_URL}/docs/${docId}#comment-${id}`
}

/**
 * Next router doesn't provide a first class way to access the browser hash,
 * so this is an SSR defensive utility to get it from window.location.hash
 */
export const getUrlHash = (url?: string): string | undefined => {
  if (typeof window === 'undefined' && !url) return

  const hash = url
    ? new URL(url.startsWith('http') ? url : `${config.FRONTEND_URL}${url}`)
        .hash
    : window.location.hash

  return hash.split('#').pop() || undefined
}

/**
 * Grabs the cardId and commentId from the URL hash if present
 */
export const parseUrlHash = (
  url?: string
): {
  cardId: string | null
  commentId: string | null
} => {
  const hash = getUrlHash(url)
  const cardId = hash?.split(CARD_HASH_PREFIX)?.[1] || null
  const commentId = hash?.split('comment-')?.[1] || null
  return {
    cardId,
    commentId,
  }
}

// Check this regex here: https://regex101.com/r/rA1Vqh/2
export const GAMMA_DOC_REGEX =
  /https:\/\/((staging\.)?gamma\.app|gamma-[^-]+-gamma(-app)?\.vercel\.app|localhost:3000|local\.gamma\.app:3000)\/docs\/[-\w\d]{15,}\/?(?:\?[^#\r\n\s]*)?(?:#[-\w\d]*)?/gm

export const getExistingQueryParams = () => {
  if (typeof window === 'undefined') return {}

  const url = new URL(window.location.href)
  const rest: { [key: string]: string } = {}
  for (const [key, value] of url.searchParams.entries()) {
    rest[key] = value
  }
  return rest
}

export const getDocIdFromUrl = (url: string) => {
  const [_prefix, docUrlSlug] = getDocPathnameParts(url)
  const urlNanoid = docUrlSlug.match(DOC_NANOID_REGEX)
  return urlNanoid ? urlNanoid[0] : null
}

/**
 * Extracts the prefix, docSlug, and optional /preview suffix from known Gamma app URLs:
 *
 * /docs/:docSlug
 * /public/:docSlug
 * /embed/:docSlug
 * /example/:docSlug
 * /demo/:docSlug
 * /sites/:siteId/pages/:docSlug
 * /sites/:siteId/pages/:docSlug/preview
 */
export function getDocPathnameParts(
  url?: string
): [string, string, string | undefined] {
  if (typeof window === 'undefined' && !url) return ['', '', undefined]

  try {
    const path = new URL(url || window.location.href).pathname
    const urlPattern =
      /^(\/(?:docs|public|demo|embed|example|sites\/[^/]+\/pages)\/([^/?]+))(\/preview)?/

    const matches = path.match(urlPattern)

    if (matches) {
      const prefix = matches[1]?.replace(/\/[^/]+$/, '') || ''
      const docSlug = matches[2] || ''
      const suffix = matches[3] || undefined

      return [prefix, docSlug, suffix]
    }
  } catch (err) {
    // This catches malformed URLs - which aren't necessarily a problem,
    // we just fall back to returning nothing
    console.warn('Error parsing URL', url, err)
  }

  return ['', '', undefined]
}

export const isValidHomeUrl = (url) => {
  return (
    !url.startsWith('/docs/') &&
    // We may not need this
    (url.startsWith('/folders/') ||
      url.startsWith('/#examples') ||
      url.startsWith('/#inspiration') ||
      url.startsWith('/#all') ||
      url.startsWith('/#sites') ||
      url.startsWith('/#trash'))
  )
}

export const isEmbedUrl = (url: string) => {
  return url.includes('/embed/')
}

export const isCreateV2Path = (pathname: string) => {
  return pathname.startsWith('/create') || pathname.startsWith('/generate/')
}

export const getDocGenerationUrl = (
  docGeneration: Pick<DocGeneration, 'id' | 'generationType'>
) => {
  if (!docGeneration.id) {
    return '/'
  }
  return docGeneration.generationType === GenerationType.Generate
    ? getGeneratedDocGenerationUrl(docGeneration.id)
    : getAdvancedDocGenerationUrl(docGeneration.id)
}

export const getGeneratedDocGenerationUrl = (docGenerationId: string) => {
  return `/create/generate/${docGenerationId}`
}

export const getAdvancedDocGenerationUrl = (docGenerationId: string) => {
  return `/generate/${docGenerationId}`
}
