import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  Button,
  createIcon,
  Divider,
  Flex,
  Heading,
  HStack,
  IconProps,
  keyframes,
  List,
  ListItem,
  ListItemProps,
  Spacer,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import capitalize from 'lodash/capitalize'

import { ProductPrice } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { formatCurrency } from 'modules/i18n/utils/currency'
import {
  getCurrencyDivisor,
  getPriceDisplayAnnually,
  getPriceDisplayMonthly,
  shouldShowCurrencyDisplayAsCode,
} from 'modules/monetization/utils'

import type { ProductKey } from '../../types'
import { getBillingCycleDetails, getProductDetails } from './constants'

const CheckIcon = createIcon({
  viewBox: '0 0 17 12',
  d: 'M0 5.82857L1.64571 4.11429L5.48571 7.2L14.8114 0L16.4571 1.71429L5.48571 12L0 5.82857Z',
})

const Price = (
  props: ListItemProps & {
    iconColor: IconProps['color']
  }
) => {
  const { children, iconColor, ...rest } = props
  return (
    <ListItem
      display="flex"
      alignItems="flex-start"
      fontWeight="medium"
      {...rest}
    >
      <CheckIcon marginEnd="3" mt="1" color={iconColor} />
      <Text as="span" display="inline-block">
        {children}
      </Text>
    </ListItem>
  )
}

const PriceAndBillingCycle = ({
  isFree,
  productPrice,
  colorScheme,
}: {
  isFree: boolean
  productPrice?: ProductPrice
  colorScheme: string
}) => {
  if (!isFree && !productPrice) {
    return null
  }

  const selectedBillingCycle =
    productPrice?.frequencyUnit === 'month'
      ? getBillingCycleDetails().monthly
      : getBillingCycleDetails().yearly

  const annualPriceDisplay =
    productPrice?.frequencyUnit === 'year'
      ? getPriceDisplayAnnually(productPrice)
      : null

  const displayAsTwoLines =
    // Break the price display into two lines when:
    // The price has many digits, e.g. 10000
    getCurrencyDivisor(productPrice?.currency) < 100 ||
    // The currency is displayed as a code, e.g. TWD
    shouldShowCurrencyDisplayAsCode(productPrice?.currency)

  return (
    <Box my="2" mb="4">
      <Stack
        align={displayAsTwoLines ? 'flex-start' : 'flex-end'}
        direction={displayAsTwoLines ? 'column' : 'row'}
      >
        <Trans comment="Example: A$16/user/month. The variable represents a currency symbol + value. If `/user/month` doesn't sound natural in your language, feel free to translate this from `per user per month` instead.">
          <Text
            fontSize="5xl"
            fontFamily="p22-mackinac-pro"
            fontWeight="bold"
            lineHeight={displayAsTwoLines ? 1 : undefined}
            color={
              !isFree
                ? mode(`${colorScheme}.600`, `${colorScheme}.200`)
                : 'inherit'
            }
          >
            {isFree
              ? formatCurrency(0, { currency: productPrice?.currency })
              : getPriceDisplayMonthly(productPrice)}
          </Text>
          <Text
            lineHeight={displayAsTwoLines ? 1 : undefined}
            pb={3.5}
            color={mode(`${colorScheme}.600`, `${colorScheme}.200`)}
          >
            / user / month
          </Text>
        </Trans>
      </Stack>
      <Text
        textAlign={displayAsTwoLines ? 'left' : undefined}
        fontWeight="bold"
        color={mode(`${colorScheme}.600`, `${colorScheme}.200`)}
      >
        {isFree ? (
          <Trans>Free version</Trans>
        ) : annualPriceDisplay ? (
          `${annualPriceDisplay} ${selectedBillingCycle.description}`
        ) : (
          capitalize(selectedBillingCycle.description)
        )}
      </Text>
    </Box>
  )
}

const heroBeforeAnimation = keyframes({
  '0%': {
    maskPosition: '0% 50%',
  },
  '50%': {
    maskPosition: '100% 50%',
  },
  '100%': {
    maskPosition: '0% 50%',
  },
})

export const ProductDetails = ({
  productKey,
  productPrice,
  isUpgrade = true,
  showProductPrice = true,
  isCurrentProduct,
  upgradeDisabled,
  canManageWorkspace,
  onClick,
}: {
  productKey: ProductKey
  productPrice?: ProductPrice
  isUpgrade?: boolean
  showProductPrice?: boolean
  isCurrentProduct?: boolean
  upgradeDisabled?: boolean
  canManageWorkspace: boolean
  badgeLabel?: string
  onClick?: () => void
}) => {
  const isFree = productKey === 'free'
  const plusCredits = useFeatureFlag('plus-credits')
  const productDetails = getProductDetails({
    plusCreditRefillAmount: plusCredits.enabled ? plusCredits.creditsToAdd : 0,
  })
  const {
    name,
    backgroundImage,
    backgroundGradient,
    isHero,
    color,
    featureHeading,
    features,
    buttonVariant,
    buttonColor,
    colorScheme,
    badgeLabel,
  } = isFree ? productDetails.free : productDetails[productKey]

  return (
    <Flex
      p={6}
      flex={1}
      flexDirection="column"
      bg={backgroundGradient}
      bgSize="contain"
      color={color}
      my={isHero ? -3 : 0}
      borderRadius={isHero ? 'md' : 'none'}
      boxShadow={isHero ? 'xl' : 'none'}
      position="relative"
      _before={
        isHero
          ? {
              content: '""',
              position: 'absolute',
              inset: 0,
              background: backgroundImage,
              backgroundSize: 'contain',
              borderRadius: 'inherit',
              animation: `${heroBeforeAnimation} 10s linear infinite`,
              maskImage:
                'linear-gradient(to left, rgba(0,0,0,.6), transparent, rgba(0,0,0,.6))',
              maskRepeat: 'repeat',
              maskSize: '160px',
            }
          : {}
      }
    >
      <HStack>
        <Heading
          color={
            isFree
              ? 'inherit'
              : mode(`${colorScheme}.600`, `${colorScheme}.200`)
          }
          pt={isHero ? 3 : 0}
          fontFamily="p22-mackinac-pro"
          fontWeight="500"
          size={{ base: 'xl', lg: 'lg' }}
        >
          {name}
        </Heading>
        <Spacer />
        <Badge
          bgGradient="linear-gradient(120deg, #3300D9 0%, #9D20C9 56.82%, #DF7A6C 150%)"
          color="white"
          shadow="2xl"
        >
          {badgeLabel}
        </Badge>
      </HStack>
      <Divider
        opacity={1}
        borderWidth="1px"
        borderColor={mode(`${colorScheme}.600`, `${colorScheme}.200`)}
        borderRadius="md"
        w="70px"
        my="6"
        mt={2}
      />

      {showProductPrice && (
        <PriceAndBillingCycle
          colorScheme={colorScheme}
          productPrice={productPrice}
          isFree={isFree}
        />
      )}
      {isFree && (
        <Button
          as={Box}
          width="fit-content"
          fontSize="md"
          colorScheme="gray"
          pointerEvents="none"
          borderWidth="1px"
          borderColor={mode(`${colorScheme}.200`, `${colorScheme}.100`)}
          my="8"
          variant="ghost"
          size="lg"
          visibility={isCurrentProduct ? 'visible' : 'hidden'}
        >
          <Text>
            <Trans>Current plan</Trans>
          </Text>
        </Button>
      )}
      {!isFree &&
        (isCurrentProduct ? (
          <Button
            as={Box}
            fontSize="md"
            colorScheme={color}
            color={isHero ? 'trueblue.500' : undefined}
            bg={isHero ? 'white' : undefined}
            textShadow="none"
            pointerEvents="none"
            my="8"
            variant={buttonVariant}
            width="fit-content"
            size="lg"
          >
            <Text>
              <Trans>Current plan</Trans>
            </Text>
          </Button>
        ) : (
          <Button
            my="8"
            variant={buttonVariant}
            size="lg"
            colorScheme={buttonColor}
            fontSize="md"
            width="fit-content"
            onClick={onClick}
            isDisabled={upgradeDisabled}
            leftIcon={
              isHero ? <FontAwesomeIcon icon={solid('sparkles')} /> : undefined
            }
            color={isHero ? 'trueblue.500' : undefined}
            bg={isHero ? 'white' : undefined}
            textShadow="none"
            _hover={{
              bg: 'trueblue.50',
              color: isHero ? 'trueblue.500' : undefined,
            }}
          >
            {isUpgrade ? (
              <Trans>Upgrade to {name}</Trans>
            ) : (
              <Trans>Downgrade to {name}</Trans>
            )}
          </Button>
        ))}

      {!canManageWorkspace && !isFree && (
        <Alert status="error" mb={6}>
          <AlertIcon />
          <AlertDescription color="var(--chakra-colors-chakra-body-text)">
            <Trans>Contact a workspace admin to upgrade.</Trans>
          </AlertDescription>
        </Alert>
      )}

      <Box zIndex="1">
        <Text fontWeight="bold" mb="4">
          {featureHeading}
        </Text>
        <List spacing="4">
          {features.map((feature, index) => (
            <Price
              key={index}
              iconColor={mode(`${colorScheme}.600`, `${colorScheme}.200`)}
            >
              {feature}
            </Price>
          ))}
        </List>
      </Box>
    </Flex>
  )
}
