import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import { GetSiteQuery } from 'modules/api/generated/graphql'
import { RootState } from 'modules/redux'

import { SiteNavItem } from './types'

export type SiteState = {
  site?: GetSiteQuery['site']
  currentDocId?: string
  currentNavItem?: SiteNavItem
}

const initialState: SiteState = {
  site: undefined,
  currentDocId: undefined,
  currentNavItem: undefined,
}

const SiteSlice = createSlice({
  name: 'Site',
  initialState,
  reducers: {
    reset: () => initialState,
    setSite(
      state: SiteState,
      action: PayloadAction<{
        site?: GetSiteQuery['site']
      }>
    ) {
      const { site } = action.payload
      state.site = site
    },
    setCurrentDocId(
      state: SiteState,
      action: PayloadAction<{
        currentDocId?: string
      }>
    ) {
      const { currentDocId } = action.payload
      state.currentDocId = currentDocId
    },
    setCurrentNavItem(
      state: SiteState,
      action: PayloadAction<{
        currentNavItem?: SiteNavItem
      }>
    ) {
      const { currentNavItem } = action.payload
      state.currentNavItem = currentNavItem
    },
  },
})

export const { setSite, setCurrentDocId, setCurrentNavItem, reset } =
  SiteSlice.actions

type SitesSliceState = Pick<RootState, 'Site'>

// Selectors
export const selectSite = (state: SitesSliceState) => state.Site.site

export const selectCurrentDocId = (state: SitesSliceState) =>
  state.Site.currentDocId

export const selectCurrentNavItem = (state: SitesSliceState) =>
  state.Site.currentNavItem

export const selectSiteSettingsChanged = (state: SitesSliceState) => {
  const site = selectSite(state)
  if (!site) return false

  const { currentDeployment } = site
  if (!currentDeployment?.siteSettings) return false

  const { siteSettings } = currentDeployment

  const themeChanged = site.themeId !== siteSettings.themeId
  const navEnabledChanged = site.navEnabled !== siteSettings.navEnabled
  const navContentChanged = !isEqual(site.navContent, siteSettings.navContent)
  const gtmContainerIdChanged =
    site.gtmContainerId !== siteSettings.gtmContainerId

  return (
    themeChanged ||
    navEnabledChanged ||
    navContentChanged ||
    gtmContainerIdChanged
  )
}

// Reducer
export const SiteReducer = SiteSlice.reducer
