import { Box } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gammaTheme } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'

import { GammaIcon } from 'gamma_components/GammaIcon'

import { Theme, ThemeType } from './types'
export const DEFAULT_ACCENT_COLOR = gammaTheme.colors.trueblue[500] as string
export const DEFAULT_HEADING_COLOR = '#000000' // black
export const DEFAULT_HEADING_COLOR_DARK = '#FFFFFF' // white
export const DEFAULT_BODY_COLOR = '#272525' // dark gray
export const DEFAULT_BODY_COLOR_DARK = gammaTheme.colors.gray[200] as string

export const DEFAULT_FONTS = {
  bodyFont: 'Inter',
  headingFont: 'Inter',
  fontSize: 1,
}

export const DEFAULT_FONT_WEIGHTS = {
  heading: 700,
  body: 400,
}

export const DARK_TEXT_LIGHTNESS = 0.4
export const LIGHT_TEXT_LIGHTNESS = 0.8

export const DEFAULT_LIGHT_CARD_BACKGROUND = 'white'
export const DEFAULT_DARK_CARD_BACKGROUND = 'rgba(12, 12, 12)'

export const ACCENT_IMAGE_SOURCE_KEY = 'accent.theme'

export const getEmptyThemeName = () => t`Untitled theme`

export const DEFAULT_THEME_ID = 'default-light'

export const EMPTY_THEME: Theme = {
  id: 'new',
  name: getEmptyThemeName(),
  archived: false,
  priority: 0,
  config: {},
  fonts: [],
}
export const FILTER_THEME_OPTIONS: {
  [key in ThemeType]: {
    label: JSX.Element
    ariaLabel: string
    description?: JSX.Element
    icon: JSX.Element
  }
} = {
  standard: {
    label: <Trans>Standard</Trans>,
    ariaLabel: t`Standard themes`,
    description: <Trans>These themes are created by Gamma.</Trans>,
    icon: (
      <Box boxSize="4">
        <GammaIcon />
      </Box>
    ),
  },
  custom: {
    label: <Trans>Custom</Trans>,
    ariaLabel: t`Custom themes`,
    description: <Trans>These themes are custom to your workspace.</Trans>,
    icon: <FontAwesomeIcon icon={regular('paintbrush-pencil')} fixedWidth />,
  },
  archived: {
    label: <Trans>Archived</Trans>,
    ariaLabel: t`Archived themes`,
    icon: <FontAwesomeIcon icon={regular('archive')} fixedWidth />,
  },
}

export const ORDERED_FILTER_THEME_OPTIONS = ['custom', 'standard', 'archived']

export const ARCHIVED_THEME_NAME_REPLACE_REGEX = /\sarchived-[0-9a-z]{15}$/
export const THEMES_DASHBOARD_LINK = `/#themes`
