import { DEFAULT_CREDIT_COSTS } from 'modules/credits/constants'

export type ExampleMemoJSON = {
  docId: string
  thumbnailUrl: string
  title: string
  disabled?: boolean
  isNew?: boolean
}

/**
 * Must enumerate all feature flags here
 */
export type FeatureFlags = {
  debugComments: boolean
  debugCardViewed: boolean
  debugLogging: boolean
  drawingBlock: boolean
  uploadPDF: boolean
  highlight: {
    enabled: boolean
    enableStrictPrivacy: boolean
  }
  realUserMetrics: boolean
  rumSampleRate: number
  rumKeystrokeSampleRate: number
  cardViewedThreshold: number
  // Enables creating new custom themes
  channelActivity: boolean
  customFonts: boolean
  docImport: boolean
  exportCard: boolean
  presentModeFlat: boolean // todo: remove
  blockReactions: boolean
  intercom: boolean
  dataSyncErrorThreshold: number
  dataSyncClockDriftTolerance: number
  screenshotsEnabled: boolean
  math: boolean
  offline: boolean
  cardWidthOptions: boolean
  layoutResizing: boolean
  tableOfContentsBlock: boolean
  presentZoom: boolean
  linkMetadata: boolean
  presentFullWidth: boolean
  presentScaleFont: boolean
  renderCollapsedCards: boolean
  cardLayoutsEnabled: boolean
  card1InCard2: boolean
  presentFullScreen: boolean
  docFullWidth: boolean
  borderless: boolean
  aiAutocomplete: boolean
  aiRewrite: boolean
  aiCardDesigner: boolean
  aiPremiumModels: boolean
  aiWizard: boolean
  aiWizardGenerateTimeout: number
  aiWizardAdvanced: boolean
  aiWizardOutlineMaxWait: number
  aiWizardAverageWaitGuided: number
  aiWizardAverageWaitAdvanced: number
  aiWizardStreaming: boolean
  aiWizardUnsplashImages: boolean
  aiWizardGoogleImages: boolean
  aiWizardBingImages: boolean
  aiWizardWebImages: boolean
  aiWizardUploadImages: boolean
  webImageProviderType: '1' | '2'
  aiChat: boolean
  aiThemeWizard: boolean
  imageView2: boolean
  publishing: boolean
  aiDebits: {
    generateImage: number
    wizardCreation: number
    chatSuggestion: number
    autocomplete: number
    generateV2: number
    generateV2WithImages: number
  }
  'ai-credits': {
    enabled: false
    referralBonus: number
    signup: number
  }
  aiRequestTimeouts: {
    getOutlineForTopic: number
    classify: number
    basicReply: number
    suggestContent: number
    rateImages: number
  }
  aiRequestRetries: {
    wizard: number
    wizardAdvanced: number
    wizardStreaming: number
    chat: number
  }
  lexicaImageSearch: boolean
  webImageSearch: boolean
  docFormats: boolean
  signupRedirectTo: 'GENERATE' | 'GET_STARTED' | 'HOME' | 'CREATE'
  openAiStatus: 'DOWN' | 'DEGRADED' | 'NORMAL'
  docEditorForcePublicStatic: boolean
  cardTextComputedEnabled: boolean
  pptImport: boolean
  hasProAccess: boolean
  upsellUXEnabled_Pro: boolean
  upsellUXEnabled_Plus: boolean
  'plus-credits': {
    creditsToAdd: number
    enabled: boolean
  }
  monthlyBillingEnabled: boolean
  autoZoomToFit: boolean
  pptExport: boolean
  pageSetup: boolean
  azureOpenAI: boolean
  passwordSignup: boolean
  docCustomCode: boolean
  aiGeneratedImages: boolean
  aiGeneratedImagesCount: number
  aiGeneratedImagesInWizard: boolean
  permanentlyDeleteDoc: boolean
  i18nEnabled: boolean
  outageButterbar:
    | { enabled: false }
    | {
        enabled: true
        key: string
        header: string
        text?: string
        buttonText?: string
        buttonLink?: string
      }

  filmstrip: boolean
  streamResumingEnabled: boolean
  sites: boolean
  sitesNav: boolean
  createv2: boolean
  deleteSite: boolean
  statsSmartLayouts: boolean
  pyramidSmartLayouts: boolean
  generatorLazyImages: boolean
  generatorGPT4: boolean
  imgProxy: boolean
  recaptcha: {
    enabled: boolean
    checkboxRiskLevels: Array<'low' | 'medium' | 'high' | 'unknown'>
  }
  mobileEditing: boolean
  churnkeyCancelFlowEnabled: boolean
  churnkeyFailedPaymentWallEnabled: boolean
  createGenerateV2: boolean
  cardNotes: boolean
  defaultAnnualBilling: boolean
  aiJSX: boolean
  editorAnimationsEnabled: boolean
}

export const FEATURE_FLAG_DEFAULTS: Readonly<FeatureFlags> = {
  debugCardViewed: false,
  debugComments: false,
  debugLogging: false,
  drawingBlock: true,
  uploadPDF: false,
  highlight: {
    enabled: false,
    enableStrictPrivacy: true,
  },
  realUserMetrics: false,
  // default sample rate is 100%
  rumSampleRate: 1,
  // default keystroke sample rate is 10%
  rumKeystrokeSampleRate: 10,
  cardViewedThreshold: 5000,
  channelActivity: false,
  customFonts: true,
  docImport: true,
  exportCard: false,
  presentModeFlat: true,
  blockReactions: true,
  intercom: true,
  dataSyncErrorThreshold: -1,
  dataSyncClockDriftTolerance: 5,
  screenshotsEnabled: false,
  math: false,
  offline: false,
  cardWidthOptions: false,
  layoutResizing: true,
  tableOfContentsBlock: true,
  presentZoom: true,
  linkMetadata: false,
  presentFullWidth: false,
  presentScaleFont: false,
  renderCollapsedCards: true,
  cardLayoutsEnabled: true,
  card1InCard2: false,
  presentFullScreen: false,
  docFullWidth: false,
  borderless: false,
  aiAutocomplete: false,
  aiRewrite: false,
  aiCardDesigner: false,
  aiWizard: true,
  aiWizardGenerateTimeout: 120000,
  aiWizardAdvanced: true,
  aiWizardOutlineMaxWait: 12000,
  aiWizardAverageWaitGuided: 20,
  aiWizardAverageWaitAdvanced: 60,
  aiWizardStreaming: true,
  aiWizardUnsplashImages: false,
  aiWizardGoogleImages: false,
  aiWizardBingImages: false,
  aiWizardWebImages: false,
  webImageProviderType: '2',
  aiWizardUploadImages: true,
  aiPremiumModels: false,
  aiChat: true,
  aiThemeWizard: false,
  aiDebits: DEFAULT_CREDIT_COSTS,
  'ai-credits': {
    enabled: false,
    referralBonus: 100,
    signup: 400,
  },
  imageView2: false,
  publishing: false,
  lexicaImageSearch: false,
  webImageSearch: false,
  docFormats: false,
  signupRedirectTo: 'HOME',
  openAiStatus: 'NORMAL',
  aiRequestTimeouts: {
    getOutlineForTopic: 4500, // Deprecated, use aiWizardOutlineMaxWait
    classify: 5000,
    basicReply: 5000,
    suggestContent: 12000,
    rateImages: 5000,
  },
  aiRequestRetries: {
    wizard: 0,
    wizardAdvanced: 0,
    wizardStreaming: 2,
    chat: 3,
  },
  docEditorForcePublicStatic: false,
  cardTextComputedEnabled: false,
  pptImport: false,
  hasProAccess: false,
  upsellUXEnabled_Pro: false,
  upsellUXEnabled_Plus: false,
  'plus-credits': {
    creditsToAdd: 400,
    enabled: false,
  },
  monthlyBillingEnabled: false,
  autoZoomToFit: false,
  pptExport: false,
  pageSetup: false,
  azureOpenAI: true,
  passwordSignup: true,
  docCustomCode: false,
  aiGeneratedImages: true,
  aiGeneratedImagesCount: 1,
  aiGeneratedImagesInWizard: true,
  permanentlyDeleteDoc: false,
  i18nEnabled: false,
  outageButterbar: {
    enabled: false,
  },
  filmstrip: true,
  streamResumingEnabled: false,
  sites: false,
  sitesNav: false,
  createv2: false,
  deleteSite: false,
  statsSmartLayouts: false,
  pyramidSmartLayouts: false,
  generatorLazyImages: false,
  generatorGPT4: false,
  imgProxy: true,
  recaptcha: {
    enabled: true,
    checkboxRiskLevels: ['medium'],
  },
  mobileEditing: false,
  churnkeyCancelFlowEnabled: false,
  churnkeyFailedPaymentWallEnabled: false,
  createGenerateV2: false,
  cardNotes: false,
  defaultAnnualBilling: true,
  aiJSX: false,
  editorAnimationsEnabled: false,
}
