import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { InnerEditorNodeViewProps } from 'modules/tiptap_editor/extensions/Footnote/InnerEditorNodeView'

type MountEditorFn = InnerEditorNodeViewProps['mountEditor']
type DestroyEditorFn = InnerEditorNodeViewProps['destroyEditor']

export interface CardNotesState {
  activeNoteCardId: string | null
  mountEditor?: MountEditorFn
  destroyEditor?: DestroyEditorFn
  isCardNotesPanelOpen: boolean
}

const initialState: CardNotesState = {
  activeNoteCardId: null,
  isCardNotesPanelOpen: false,
}

const CardNotesStateSlice = createSlice({
  name: 'CardNotes',
  initialState,
  reducers: {
    setCardNoteProps: (
      state,
      action: PayloadAction<{
        activeNoteCardId?: string
        mountEditor?: MountEditorFn
        destroyEditor?: DestroyEditorFn
      }>
    ) => {
      const { activeNoteCardId, mountEditor, destroyEditor } = action.payload
      state.activeNoteCardId = activeNoteCardId || null
      state.mountEditor = mountEditor
      state.destroyEditor = destroyEditor
    },
    setCardNotesPanelOpen: (
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ) => {
      state.isCardNotesPanelOpen = action.payload.isOpen
    },
  },
})

type ReduxState = {
  [CardNotesStateSlice.name]: CardNotesState
}

export const { setCardNoteProps, setCardNotesPanelOpen } =
  CardNotesStateSlice.actions

export const selectCardNotesState = (state: ReduxState) => {
  return {
    activeNoteCardId: state.CardNotes.activeNoteCardId,
    mountEditor: state.CardNotes.mountEditor,
    destroyEditor: state.CardNotes.destroyEditor,
  }
}

export const selectActiveNoteCardId = (state: ReduxState) => {
  return state.CardNotes.activeNoteCardId
}

export const selectIsCardNotesPanelOpen = (state: ReduxState) => {
  return state.CardNotes.isCardNotesPanelOpen
}

export const reducer = CardNotesStateSlice.reducer
