import { uniq } from 'lodash'

import { config } from 'config'
import { featureFlags } from 'modules/featureFlags'

const GAMMA_CDN_HOSTNAME_PROD = 'https://cdn.gamma.app'
const GAMMA_CDN_HOSTNAME_STAGING = 'https://cdn-staging.gamma.app'
const GAMMA_CDN_HOSTNAMES = [
  GAMMA_CDN_HOSTNAME_PROD,
  GAMMA_CDN_HOSTNAME_STAGING,
]
const UNSPLASH_HOST = 'https://images.unsplash.com/'

export const isGammaCDNUrl = (url: string) => {
  return GAMMA_CDN_HOSTNAMES.some((hostname) => url.startsWith(hostname + '/'))
}

export type ImageResizeParams = {
  width: number
  height: number
  quality: number

  // See https://docs.imgproxy.net/generating_the_url?id=processing-options
  resizing_type: 'fit' | 'fill' | 'fill-down' | 'force' | 'auto'
}

const DEFAULT_RESIZE_PARAMS: Partial<ImageResizeParams> = {
  quality: 80,
  resizing_type: 'fit',
} as const

/**
 * Cloudflare supports a different set of parameters than ImgProxy
 * In the event we need to fallback to Cloudflare, use this list
 * to filter out unsupported params, unless we have a mapping
 * defined below in ImgProxyToCloudflareMap
 */
const CloudflareSupportedParams: (keyof ImageResizeParams | 'onerror')[] = [
  'width',
  'height',
  'quality',
  'onerror',
]

/**
 * Map ImgProxy params to Cloudflare params so that
 * so that we can gracefully fallback to Cloudflare if needed
 */
const ImgProxyToCloudflareMap = {
  resizing_type: {
    cfKey: 'fit',
    valueMap: {
      fit: 'scale-down',
      fill: 'crop',
      'fill-down': 'scale-down',
      force: null,
      auto: null,
    },
  },
} as const

export const getImgProxyUrl = (
  url: string,
  params: Partial<ImageResizeParams>
) => {
  if (!params.width && !params.height) return url

  const urlParams = Object.entries({ ...DEFAULT_RESIZE_PARAMS, ...params })
    .map(([key, value]) => `${key}:${value}`)
    .filter(Boolean)
    .join('/')

  const result = `${config.IMAGE_WORKER_HOST}/resize/${urlParams}/${url}`

  return result
}

const getCloudflareUrlParams = (params: Partial<ImageResizeParams>) => {
  const urlParams = Object.entries({
    ...DEFAULT_RESIZE_PARAMS,
    ...params,
    onerror: 'redirect', // Always redirect to the original image if there's an error
  })
    .map(([key, value]) => {
      // Check if the key needs to be mapped
      if (key in ImgProxyToCloudflareMap) {
        const { cfKey, valueMap } = ImgProxyToCloudflareMap[key]
        if (value in valueMap) {
          return `${cfKey}=${valueMap[value]}`
        }
        return null
      }
      // Otherwise, if the key isn't supported, return null
      if (!CloudflareSupportedParams.includes(key as any)) return null
      return `${key}=${value}`
    })
    .filter(Boolean)
    .join(',')
  return urlParams
}

// Replace all instances of Gamma CDN urls in the input (e.g. a background-image prop)
// with resized versions via Cloudflare
export const resizeGammaCDNUrls = (
  input: string,
  params: Partial<ImageResizeParams>
) => {
  if (!params.width && !params.height) return input

  if (featureFlags.get('imgProxy')) {
    return getImgProxyUrl(input, params)
  }

  const urlParams = getCloudflareUrlParams(params)
  GAMMA_CDN_HOSTNAMES.forEach((hostname) => {
    input = input.replace(
      hostname,
      // https://developers.cloudflare.com/images/image-resizing/url-format/
      `${hostname}/cdn-cgi/image/${urlParams}`
    )
  })
  return input
}

const RESIZE_THRESHOLD = 1.5 // Current / target width

export const THUMBNAIL_RESIZE_PARAMS = {
  width: 400,
  height: 400,
}

// Works for any image URL, including ones not on our CDN
// If the URL is not on our CDN (e.g. from image search),
// it will proxy via our CDN
// Some images may error, so this should always be used with a fallback
export const resizeAndProxyImageUrl = (
  url: string,
  params: Partial<ImageResizeParams>,
  meta?: { width: number; height: number; frame_count?: number }
) => {
  if (!params.width && !params.height) return url

  // Don't resize animated GIFs
  if (meta?.frame_count && meta.frame_count > 1) return url

  // Don't proxy/resize if it's on Unsplash, since we already resize through them
  if (url.startsWith(UNSPLASH_HOST)) return url

  if (featureFlags.get('imgProxy')) {
    return getImgProxyUrl(url, params)
  }

  // If we're using Cloudflare, don't bother if it's small enough already
  if (
    (meta?.width &&
      params.width &&
      meta.width <= params.width * RESIZE_THRESHOLD) ||
    (meta?.height &&
      params.height &&
      meta.height <= params.height * RESIZE_THRESHOLD)
  ) {
    return url
  }

  const urlParams = getCloudflareUrlParams(params)
  const hostname =
    config.APPLICATION_ENVIRONMENT === 'production'
      ? GAMMA_CDN_HOSTNAME_PROD
      : GAMMA_CDN_HOSTNAME_STAGING
  return `${hostname}/cdn-cgi/image/${urlParams}/${url}`
}

// Converts one or more image URLs into a CSS background-image value
// Automatically removes duplicates and undefined/null values
export const backgroundImageFromUrls = (
  ...images: (string | null | undefined)[]
) => {
  return uniq(images)
    .filter((url) => url) // Exclude blank/undefined
    .map((url: string) => `url("${url}")`)
    .join(', ')
}

/**
 * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 * See: https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
 */
export function dataURLtoFile(dataurl, filename = 'image') {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const ext = mime.match(/image\/(.*)/)[1]

  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], `${filename}.${ext}`, { type: mime })
}

export const isDownloadableImageSrc = async (src: string): Promise<boolean> => {
  try {
    await downloadImage(src)
    return true
  } catch (e) {
    return false
  }
}

export const downloadImage = (src?: string) => {
  return new Promise((res, rej) => {
    if (!src) {
      res(null)
    } else {
      const img = new Image()
      img.src = src
      img.onload = res
      img.onerror = rej
    }
  })
}

export function isImageMimeType(type: string): boolean {
  return /^image/i.test(type)
}

export const isValidImageString = (str: string) => {
  // TODO maybe we want this in something else
  if (/^data:image/.test(str)) {
    return true
  }

  // Verify that the image is a valid HTTPS url
  // AND
  // That the string is a valid CSS background-image value
  if (!/^https:\/\//.test(str)) return false

  // Set the string as a URL on a div and check if the browser allowed it to be set
  const el = document.createElement('div')
  el.style.backgroundImage = `url("${str}")`
  if (!el.style.backgroundImage) return false

  return true
}

/**
 * These two functions isHEICFileType and isHEICFileExtension use regex to determine
 * if something is HEIC / HEIF.
 *
 * NOTE: the template on transloadit will also need to be updated to filter for these
 * same values
 */
export function isHEICFileType(mimeType: string): boolean {
  return /heic|heif/i.test(mimeType)
}

export function isHEICFileExtension(filePath: string): boolean {
  return /\.heic|\.heif$/i.test(filePath)
}

export function svgToFile(svg: string): File {
  const type = 'image/svg+xml;charset=utf-8'
  const blob = new Blob(['<?xml version="1.0" standalone="no"?>\r\n', svg], {
    type,
  })
  const file = new File([blob], 'pasted.svg', {
    type,
  })
  return file
}
