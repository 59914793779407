import { t } from '@lingui/macro'

import { lightenColor } from 'utils/color'

import { ThemeBase } from '../types'
import { DefaultThemeBase } from './default'

export const IcebreakerThemeBase: ThemeBase = {
  ...DefaultThemeBase,
  key: 'icebreaker',
  name: () => t`Icebreaker`,
  description: () => t`Clean edges with professional monochrome colors`,
  getCSSVars(props) {
    const { boxColor, cardColor } = props
    return {
      ...DefaultThemeBase.getCSSVars(props),
      '--box-background-color': lightenColor(boxColor ?? cardColor, -5),
      '--interactive-border': `0.0625em solid var(--accent-color-border)`,
      '--box-border-radius': '0.5em',
    }
  },
  boxSx: {
    border: '0px',
    borderRadius: 'var(--box-border-radius)',
  },
  cardSx: {
    borderRadius: 'var(--box-border-radius)',
    backgroundColor: 'var(--card-color)',
  },
  clickableSx: {
    border: 'var(--interactive-border)',
    backgroundColor: 'var(--box-background-color)',
  },
  smartLayoutBoxSx: {
    borderRadius: 'var(--box-border-radius)',
    backgroundColor: 'var(--box-background-color)',
  },
  svgShapeSx: {
    fill: 'var(--box-background-color)',
  },
  smartLayoutLineSx: {
    backgroundColor: 'var(--box-background-color)',
  },
  smartLayoutContentSx: {},
  tableSx: {
    border: '0px',
    borderRadius: 'var(--box-border-radius)',
    'tbody tr': {
      _odd: {
        backgroundColor: 'var(--box-background-color)',
      },
    },
  },
}
