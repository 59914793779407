import { t } from '@lingui/macro'

import { colorWithOpacity, isColorDark } from 'utils/color'

import { ThemeBase } from '../types'
import { DefaultThemeBase } from './default'
import { Soft3DSmallOuterShadow } from './Soft3DDefs'

export const Soft3DThemeBase: ThemeBase = {
  key: 'soft3d',
  name: () => t`Soft 3D`,
  description: () => t`Smooth rounded edges with a soft 3D effect`,
  getCSSVars(props) {
    const { cardColor, accentColor, boxColor } = props
    const isDark = isColorDark(cardColor)
    return {
      ...DefaultThemeBase.getCSSVars(props),
      '--shadow-1-color': isDark
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(255, 255, 255, 0.7)',
      '--shadow-2-color': isDark
        ? 'rgba(0, 0, 0, 0.25)'
        : 'rgba(189, 200, 223, 0.7)',
      '--small-shadow-distance': '0.125em',
      '--small-shadow-distance-negative': '-0.125em',
      '--small-shadow-blur': '0.25em',
      '--svg-shadow-blur': '0.075em',
      '--big-shadow-distance': '0.375em',
      '--big-shadow-distance-negative': '-0.375em',
      '--big-shadow-blur': '0.75em',
      '--outer-shadow-small': `var(--small-shadow-distance-negative) var(--small-shadow-distance-negative) var(--small-shadow-blur) var(--shadow-1-color), var(--small-shadow-distance) var(--small-shadow-distance) var(--small-shadow-size) var(--shadow-2-color)`,
      '--outer-shadow-big': `var(--big-shadow-distance-negative) var(--big-shadow-distance-negative) var(--big-shadow-blur) var(--shadow-1-color), var(--big-shadow-distance) var(--big-shadow-distance) var(--big-shadow-distance) var(--shadow-2-color)`,
      '--inner-shadow-small': `inset var(--small-shadow-distance-negative) var(--small-shadow-distance-negative) var(--small-shadow-blur) var(--shadow-1-color), inset var(--small-shadow-distance) var(--small-shadow-distance) var(--small-shadow-blur) var(--shadow-2-color)`,
      '--line-thickness': `0.45em`,
      '--table-stripe': colorWithOpacity(accentColor, 0.05),
      '--box-border-radius': '1em',
      '--smart-layout-background-color': boxColor ?? cardColor,
    }
  },
  cardSx: {
    borderRadius: '1.5em',
    backgroundColor: 'var(--card-color)',
    boxShadow: 'var(--outer-shadow-big)',
  },
  boxSx: {
    border: '0px',
    boxShadow: 'var(--inner-shadow-small)',
    borderRadius: 'var(--box-border-radius)',
  },
  clickableSx: {
    boxShadow: 'var(--outer-shadow-big)',
    backgroundColor: 'var(--card-color)',
    border: '0px !important', // Override default button borders
  },
  imageSx: {
    borderRadius: 'var(--box-border-radius)',
    // Temporarily disabling since it doesn't work with transparent images. G-4008
    // boxShadow: 'var(--outer-shadow-small)',
    // border: '1px solid var(--card-color)',
  },
  smartLayoutBoxSx: {
    boxShadow: 'var(--inner-shadow-small)',
    borderRadius: '0.5em',
    backgroundColor: 'var(--smart-layout-background-color)',
  },
  smartLayoutLineSx: {
    boxShadow: 'var(--inner-shadow-small)',
    backgroundColor: 'var(--smart-layout-background-color)',
    borderRadius: '0.5em',
  },
  svgAccentSx: {
    fill: `url(#theme-accent-gradient-linear) var(--link-color)`,
  },
  cssAccentSx: {
    backgroundImage: 'var(--accent-gradient)',
    backgroundColor: 'var(--link-color)',
  },
  svgShapeSx: {
    fill: 'var(--smart-layout-background-color)',
    filter: `url(#soft3d-small-inner-shadow)`,
  },
  SVGDefs: Soft3DSmallOuterShadow,
  tableSx: {
    boxShadow: 'var(--inner-shadow-small)',
    borderRadius: 'var(--box-border-radius)',
    'tbody tr': {
      _even: {
        backgroundColor: `var(--table-stripe)`,
      },
      _last: {
        borderBottomRadius: 'var(--box-border-radius)',
      },
    },
  },
}
