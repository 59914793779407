import jsCookie from 'js-cookie'

import { config } from 'config'
import { Doc, PublicRoute, PublicSite, PublishedDoc, Route } from 'modules/api'
import {
  ensurePathHasForwardSlash,
  getSiteManagerUrlSingleRoute,
} from 'modules/sites/utils'
import { getCardHash, getDocIdFromUrl, parseUrlHash } from 'utils/url'

const getGammaHostsForEnvironment = () => {
  if (config.APPLICATION_ENVIRONMENT === 'production') {
    return [/gamma\.app/, /\.*-gamma-app\.vercel\.app/]
  }
  if (config.APPLICATION_ENVIRONMENT === 'staging') {
    return [/staging\.gamma\.app/, /\.*-gamma-app.vercel.app/, /\.*\.csb.app/]
  }
  if (config.APPLICATION_ENVIRONMENT === 'dev') {
    return [/localhost:3000/, /local\.gamma\.app/]
  }
  return [/gamma\.app/]
}

export const isGammaHost = (host: string) => {
  const gammaHosts = getGammaHostsForEnvironment()
  return gammaHosts.some((h) => h.test(host))
}

export const parseGammaDocUrl = (url: string) => {
  const docId = getDocIdFromUrl(url)
  if (!docId) return false
  const { cardId } = parseUrlHash(url)
  return { docId, cardId }
}

export const isLinkToCurrentPage = (url: string, doc?: Doc | PublishedDoc) => {
  if (!url || !doc || typeof window === 'undefined') return false

  // If it's a gamma.app/... link, see if it points to the same docId
  if (isGammaHost(url)) {
    const docUrl = parseGammaDocUrl(url)
    if (docUrl) {
      return docUrl.docId === doc.id && !docUrl.cardId // omit card links
    }
  }

  // If it's a relative link like /about, see if it goes here
  if (url.startsWith('/')) {
    // In a published context, check of it matches the current route
    if (url === window.location.pathname) {
      return true
    }

    // In an editor context, check if it matches any of the routes
    if (doc.site?.routes) {
      const routes: (Route | PublicRoute)[] = doc.site.routes
      const docRoute = routes.find((r) => r.docId === doc.id)
      if (docRoute) {
        return ensurePathHasForwardSlash(docRoute.path) === url
      }
    }
  }

  // If it's an absolute path, run the same check but also confirm the domain matches
  if (url.startsWith('http')) {
    try {
      const { host, pathname } = new URL(url)
      // In a published context, check if it matches the current window page
      if (
        pathname === window.location.pathname &&
        host === window.location.host
      ) {
        return true
      }

      // In the editor context, check if it matches the site's domain and doc route
      if (
        doc?.site &&
        'domains' in doc.site &&
        doc.site.domains?.some((d) => d.name === host)
      ) {
        // const domains = doc.site && 'domains' in doc.site ? doc.site.domains : null
        const docRoute = (doc.site.routes as Route[])?.find(
          (r) => r.docId === doc.id
        )
        if (docRoute) {
          return ensurePathHasForwardSlash(docRoute.path) === pathname
        }
      }
    } catch (err) {
      // Invalid URLs will cause an error, which we can safely ignore
    }
  }

  return false
}

// For published pages, we want to use relative links like /about or #team
// to stay on the same domain/page without a reload.
export const getRelativeOrAbsoluteUrl = ({
  url,
  doc,
  previewEnabled,
}: {
  url: string
  doc?: Doc | PublishedDoc
  previewEnabled?: boolean
}) => {
  const docUrl = parseGammaDocUrl(url)
  if (!docUrl || !doc) return url

  if (docUrl.docId === doc.id && docUrl.cardId) {
    return getCardHash(docUrl.cardId)
  }

  const site = doc.site
  if (site?.routes) {
    const routes = site.routes || []
    const siteId = 'id' in site ? site.id : null
    for (const route of routes) {
      if (route.docId !== docUrl.docId) {
        continue
      }
      if (previewEnabled && siteId) {
        return getSiteManagerUrlSingleRoute(siteId, route.docId, true)
      }
      return ensurePathHasForwardSlash(route.path)
    }
  }
  return url
}

const isCustomDomain = () => {
  if (typeof window === 'undefined') return undefined

  // Allows the edge server to indicate that the page should be
  // treated as a custom domain (either via cookie or global variable)
  if (
    jsCookie.get('gammaPublishedPage') === 'true' ||
    window.__GAMMA_PUBLISHED_PAGE__
  ) {
    return true
  }

  return getGammaHostsForEnvironment().every(
    (pattern) => window.location.host.match(pattern) === null
  )
}
// TODO - Move this utility into the sites module

// Use the published version of the renderer when we're server side
// OR
// On a known published page, e.g. /published/[docId]
// OR
// On a custom domain
export const shouldUsePublishedVersion = () => {
  //
  if (typeof window === 'undefined') return true

  if (
    window.location.host.match(/localhost:8080/) // Cypress component testing
  ) {
    return false
  }

  const { pathname } = window.location

  const shouldUse =
    pathname.startsWith('/published/') ||
    pathname.startsWith('/published_mobile/') ||
    isCustomDomain()

  return shouldUse
}

export const shouldShowSiteNav = (
  site?: Pick<PublicSite, 'navContent' | 'navEnabled'>
) => {
  if (!site) return false
  return site.navEnabled && !!site.navContent
}
