import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip, InfoTip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'

import {
  FREE_PROPER_NOUN,
  GAMMA_ARTIFACT_PROPER_NOUN_PLURAL,
  GAMMA_PROPER_NOUN,
  PLUS_PROPER_NOUN,
  PRO_PROPER_NOUN,
} from 'modules/i18n/properNouns'

import {
  BillingCycleKey,
  BillingCycleDetail,
  ProductKey,
  ProductDetail,
} from '../../types'

type ProductDetails = Record<ProductKey, ProductDetail>

type ProductDetailsOptions = {
  plusCreditRefillAmount?: number
}

export const getProductDetails = ({
  plusCreditRefillAmount = 0,
}: ProductDetailsOptions = {}): ProductDetails => ({
  free: {
    name: FREE_PROPER_NOUN,
    description: t`For personal use and manual creation`,
    colorScheme: 'gray',
    featureHeading: t`What you get:`,
    features: [
      t`400 AI credits at signup`,
      t`Unlimited users & ${GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}`,
      t`PDF export (${GAMMA_PROPER_NOUN} branded)`,
      t`PPT export (${GAMMA_PROPER_NOUN} branded)`,
      t`7-day change history`,
      t`Basic analytics`,
    ],
  },
  plus: {
    name: PLUS_PROPER_NOUN,
    description: t`All the essentials, no ${GAMMA_PROPER_NOUN} branding`,
    buttonVariant: 'outline',
    colorScheme: 'trueblue',
    featureHeading: t`Plan includes:`,
    features: [
      <Trans key="credit-refill">
        +{plusCreditRefillAmount} AI credits every month{' '}
        <InfoTip
          label={t`Credits are added immediately on upgrade, and every month after that. Each user in your workspace will get their own ${plusCreditRefillAmount} credits.`}
          color="gray.700"
        />
      </Trans>,
      t`Remove "Made with ${GAMMA_PROPER_NOUN}" badge`,
      t`PDF export`,
      t`PPT export`,
      t`30-day change history`,
      t`Unlimited folders`,
    ],
    dontChurnMessage: t`Don't lose your AI credits.`,
  },
  pro: {
    name: PRO_PROPER_NOUN,
    description: t`For professional output and unlimited AI`,
    color: 'white',
    buttonVariant: 'solid',
    buttonColor: 'white',
    colorScheme: 'white',
    isHero: true,
    backgroundGradient:
      'linear-gradient(134deg, #28039E 25.52%, #B539E0 88%, #DF7A6C 100%)',
    backgroundImage: `url(https://cdn.gamma.app/zc87vhr30n8uf3n/bf962e249e17461184fafb003ac42263/original/stars-3.png)`,

    featureHeading: t`Plan includes:`,
    features: [
      t`Unlimited AI creation`,
      <Trans key="advanced-models">
        Advanced AI generation{' '}
        <InfoTip
          label={t`Generate up to 25 cards with up to 10,000 input tokens`}
          color="gray.300"
        />
      </Trans>,
      t`Remove "Made with ${GAMMA_PROPER_NOUN}" badge`,
      t`Priority support`,
      t`Custom fonts`,
      t`Unlimited change history`,
      t`Detailed analytics`,
    ],
    badgeLabel: t`Unlimited AI`,
    dontChurnMessage: t`Don't lose unlimited AI.`,
  },
})

export const getBillingCycleDetails = (): Record<
  BillingCycleKey,
  BillingCycleDetail
> => {
  return {
    monthly: {
      name: t`Monthly billing`,
      description: t`billed monthly`,
    },
    yearly: {
      name: t`Yearly billing`,
      description: t`billed annually`,
    },
  }
}
