import memoize from 'fast-memoize'
import { sortBy } from 'lodash'
import tinycolor from 'tinycolor2'

export const getColorLightness = (color: tinycolor.ColorInput) => {
  return tinycolor(color).toHsl().l
}

export const colorWithLightness = memoize(
  (color: tinycolor.ColorInput, lightness: number) => {
    const hsl = tinycolor(color).toHsl()
    hsl.l = lightness
    return tinycolor(hsl).toHex8String()
  }
)

export const lightenColor = memoize(
  (color: tinycolor.ColorInput, lightness: number) => {
    return tinycolor(color).lighten(lightness).toHex8String()
  }
)

export const saturateColor = memoize(
  (color: tinycolor.ColorInput, saturation: number) => {
    return tinycolor(color).saturate(saturation).toHex8String()
  }
)

// Brighten is like lighten, but it pushes things more toward gray
export const brightenColor = memoize(
  (color: tinycolor.ColorInput, lightness: number) => {
    return tinycolor(color).brighten(lightness).toHex8String()
  }
)

export const colorWithOpacity = memoize(
  (color: tinycolor.ColorInput, opacity: number) => {
    return tinycolor(color).setAlpha(opacity).toHex8String()
  }
)

const DEFAULT_CONTRAST_RATIO = 4.5 // AA Small / AAA Large

export const isColorReadable = (
  textColor: string,
  backgroundColor: string,
  contrastRatio: number = DEFAULT_CONTRAST_RATIO
) => {
  return tinycolor.readability(textColor, backgroundColor) >= contrastRatio
}

const LIGHTNESS_OPTIONS = [0, 0.15, 0.3, 0.4, 0.6, 0.7, 0.85, 1]

export const makeColorReadable = memoize(
  (
    color: string,
    contrast: string,
    contrastRatio: number = DEFAULT_CONTRAST_RATIO,
    whiteBlackOnly: boolean = false
  ) => {
    if (isColorReadable(color, contrast, contrastRatio)) {
      return color
    }

    // Depending on the context, we might generate different shades of the same color (e.g. for links)
    // or just fall back to white and black (for body text)
    if (whiteBlackOnly) {
      const whiteReadability = tinycolor.readability('#FFFFFF', contrast)
      const blackReadability = tinycolor.readability('#000000', contrast)
      if (whiteReadability > blackReadability) {
        return '#FFFFFF'
      } else {
        return '#000000'
      }
    }

    const colorLightness = getColorLightness(color)
    const alternateColors = sortBy(LIGHTNESS_OPTIONS, (lightness) =>
      Math.abs(lightness - colorLightness)
    ).map((lightness) => colorWithLightness(color, lightness))

    let mostReadable = alternateColors[0],
      bestReadability = 0
    for (const option of alternateColors) {
      const readability = tinycolor.readability(option, contrast)
      if (readability >= contrastRatio) {
        return option
      }
      if (!bestReadability || readability > bestReadability) {
        mostReadable = option
        bestReadability = readability
      }
    }
    return mostReadable
  }
)

export const isColorDark = memoize((color: tinycolor.ColorInput) => {
  return tinycolor(color).isDark()
})
