import { createSlice } from '@reduxjs/toolkit'
import { JSONContent } from '@tiptap/core'

import { RootState } from 'modules/redux'

export type SuggestionVariant = {
  label: string
  id: string
  card: JSONContent // On edit, we could just update this value
}

export type CardSuggestionMap = Record<
  string, // CardId
  {
    selected?: string // Which variant is selected
    variants: SuggestionVariant[] // All variants
  }
>

export type CardSuggestionState = {
  cardSuggestionMap: CardSuggestionMap
}

const initialState: CardSuggestionState = {
  cardSuggestionMap: {},
}

const AIVariationsSlice = createSlice({
  name: 'AIVariations',
  initialState,
  reducers: {
    reset: () => initialState,
    addCardVariant(
      state,
      action: {
        payload: {
          cardId: string
          variant: SuggestionVariant
          autoselect?: boolean
        }
      }
    ) {
      const { cardId, variant, autoselect } = action.payload
      state.cardSuggestionMap[cardId] ??= {
        variants: [],
      }

      state.cardSuggestionMap[cardId].variants.push(variant)

      if (autoselect) {
        state.cardSuggestionMap[cardId].selected = variant.id
      }
    },
    addCardVariants(
      state,
      action: {
        payload: {
          cardId: string
          variant: SuggestionVariant
          autoselect?: boolean
        }[]
      }
    ) {
      const variants = action.payload
      variants.forEach(({ cardId, variant, autoselect }) => {
        state.cardSuggestionMap[cardId] ??= {
          variants: [],
        }

        state.cardSuggestionMap[cardId].variants.push(variant)

        if (autoselect) {
          state.cardSuggestionMap[cardId].selected = variant.id
        }
      })
    },
    selectCardVariant(
      state,
      action: {
        payload: {
          cardId: string
          variantId: string
        }
      }
    ) {
      const { cardId, variantId } = action.payload
      const cardData = state.cardSuggestionMap[cardId]
      if (cardData) {
        cardData.selected = variantId
      }
    },
  },
})

export const { reset, addCardVariants, selectCardVariant } =
  AIVariationsSlice.actions

type AIVariationsSliceState = Pick<RootState, 'AIVariations'>

// Selectors

export const selectSuggestionCardMap = (state: AIVariationsSliceState) =>
  state.AIVariations.cardSuggestionMap

export const selectSuggestionCardMapCard =
  (cardId: string) => (state: AIVariationsSliceState) =>
    state.AIVariations.cardSuggestionMap[cardId]

export const selectSelectedCardVariant =
  (cardId: string) => (state: AIVariationsSliceState) => {
    const data = selectSuggestionCardMapCard(cardId)(state)
    if (!data) return null
    const { selected, variants } = data
    return variants.find((variant) => variant.id === selected)
  }

// Reducer
export const AIVariationsReducer = AIVariationsSlice.reducer
