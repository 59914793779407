import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { withGoogleTagManager } from 'modules/googleTagManager'
import { sendGTMPurchaseEvent } from 'modules/googleTagManager/events'
import {
  GAMMA_PROPER_NOUN,
  GAMMA_PRO_PROPER_NOUN,
  PLUS_PROPER_NOUN,
  PRO_PROPER_NOUN,
} from 'modules/i18n/properNouns'
import { useMonetizationContext } from 'modules/monetization/context'
import { BillingCycleKey, ProductKey } from 'modules/monetization/types'
import {
  getCurrencyDivisor,
  getProductForWorkspace,
  getProOrPlusProductPrice,
  getSubscriptionPriceAndInterval,
} from 'modules/monetization/utils'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { useCanManageWorkspace, useUserContext } from 'modules/user/context'

import { PURCHASE_COMPLETE_MESSAGE } from '../../constants'
import { BillingCycleSwitcher } from './BillingCycleSwitcher'
import { getProductDetails } from './constants'
import { ProductDetails } from './ProductDetails'
import { useUpsellPro } from './useUpsellPro'
import { getCustomerPortalURL, popupCenter } from './utils'

const USER_POLLING_INTERVAL = 1000

export const UpsellModalPro = ({
  isUpsellModalOpen,
  closeUpsellModal,
}: {
  isUpsellModalOpen: boolean
  closeUpsellModal: () => void
}) => {
  const analytics = useAnalytics()
  const upsellUXEnabled_Plus = useFeatureFlag('upsellUXEnabled_Plus')
  const monthlyBillingEnabled = useFeatureFlag('monthlyBillingEnabled')
  const plusCreditsEnabled = useFeatureFlag('plus-credits')
  const canManageWorkspace = useCanManageWorkspace()
  const { subscription, product } = useMonetizationContext()
  const { currentWorkspace, user, refetch } = useUserContext()

  const { frequency } = getSubscriptionPriceAndInterval(subscription)
  const defaultAnnual = useFeatureFlag('defaultAnnualBilling')
  const [selectedBillingCycleKey, setSelectedBillingCycleKey] =
    useState<BillingCycleKey>(defaultAnnual ? 'yearly' : 'monthly')

  // If the user is already paying for monthly, show monthly options
  useEffect(() => {
    if (!frequency) return
    const cycle = frequency === 'month' ? 'monthly' : 'yearly'
    setSelectedBillingCycleKey(cycle)
  }, [frequency])

  const [purchaseComplete, setPurchaseComplete] = useState(false)
  const hasTrackedModalView = useRef(false)
  const currentProduct = getProductForWorkspace(currentWorkspace)

  const workspaceId = currentWorkspace?.id

  const { selectedProductPrices, annualDiscountPercentage, checkoutUrls } =
    useUpsellPro({
      workspaceId,
      canManageWorkspace,
      selectedBillingCycleKey,
    })

  const showPlusUX = upsellUXEnabled_Plus || product === 'plus'
  const canCreateNewSubscription = canManageWorkspace && checkoutUrls
  const canManageExistingSubscription =
    product && subscription?.createdByUser?.id === user?.id
  const upgradeDisabled =
    !canCreateNewSubscription && !canManageExistingSubscription

  const currentSubscriptionProductPrice = getProOrPlusProductPrice(subscription)

  const showBillingCycleSwitcher =
    // TODO - Figure out how to handle changing monthly<>yearly billing
    // https://linear.app/gamma-app/issue/G-4856/allow-users-to-switch-from-monthly-to-annual-and-vice-versa
    !product && monthlyBillingEnabled

  useEffect(() => {
    if (product || !purchaseComplete) return

    // Poll for the workspace subscription to be updated
    // once a purchase is complete
    const interval = setInterval(() => {
      refetch?.()
    }, USER_POLLING_INTERVAL)

    refetch?.()

    return () => {
      clearInterval(interval)
    }
  }, [purchaseComplete, product, refetch])

  useEffect(() => {
    if (!isUpsellModalOpen) {
      setPurchaseComplete(false)
    }
  }, [isUpsellModalOpen])

  useEffect(() => {
    if (hasTrackedModalView.current) return
    if (
      product ||
      !canManageWorkspace ||
      !isUpsellModalOpen ||
      !selectedProductPrices
    ) {
      return
    }

    hasTrackedModalView.current = true
    analytics?.track(SegmentEvents.GAMMA_PRO_UPSELL_PRODUCTS_VIEWED, {
      price: selectedProductPrices.pro?.price,
      price_plus: selectedProductPrices?.plus?.price,
      currentProduct,
      products: ['pro', 'plus'],
      currency: selectedProductPrices.pro?.currency,
      frequency: selectedProductPrices.pro?.frequencyUnit,
      plus_credits: plusCreditsEnabled,
      default_annual: defaultAnnual,
    })
  }, [
    isUpsellModalOpen,
    product,
    currentProduct,
    canManageWorkspace,
    analytics,
    selectedProductPrices,
    plusCreditsEnabled,
    defaultAnnual,
  ])

  const handleSelectedBillingCycleKeyChange = useCallback(
    (billingCycle: BillingCycleKey) => {
      setSelectedBillingCycleKey(billingCycle)
      analytics?.track(SegmentEvents.GAMMA_PRO_BILLING_CYCLE_CLICK, {
        price: selectedProductPrices?.pro?.price,
        price_plus: selectedProductPrices?.plus?.price,
        products: ['pro', 'plus'],
        currency: selectedProductPrices?.pro?.currency,
        frequency: selectedProductPrices?.pro?.frequencyUnit,
        billingCycle,
      })
    },
    [analytics, selectedProductPrices]
  )

  const handleCheckoutClick = useCallback(
    (productKey: ProductKey) => () => {
      if (product) {
        window.open(getCustomerPortalURL(), '_blank')
        return
      }
      if (!checkoutUrls) return

      const checkoutUrl = checkoutUrls[productKey]
      const selectedProductPrice =
        productKey === 'plus'
          ? selectedProductPrices?.plus
          : selectedProductPrices?.pro

      analytics?.track(SegmentEvents.CHECKOUT_STARTED, {
        price: selectedProductPrice?.price,
        productPriceId: selectedProductPrice?.id,
        product: productKey,
        currency: selectedProductPrice?.currency,
        frequency: selectedProductPrice?.frequencyUnit,
        plus_credits: plusCreditsEnabled,
      })

      const windowObject = popupCenter({
        url: checkoutUrl,
        title: 'Gamma',
        w: 1200,
        h: 1000,
      })

      window.addEventListener(
        'message',
        (event) => {
          if (event.data.type === PURCHASE_COMPLETE_MESSAGE) {
            setPurchaseComplete(true)
            setTimeout(() => {
              windowObject?.close()
            }, 100)
            const trackingData = {
              price: selectedProductPrice?.price,
              productPriceId: selectedProductPrice?.id,
              product: productKey,
              currency: selectedProductPrice?.currency,
              frequency: selectedProductPrice?.frequencyUnit,
              plus_credits: plusCreditsEnabled,
            }
            analytics?.track(SegmentEvents.PURCHASE_COMPLETE, trackingData)
            const gtmTrackingData = { ...trackingData }
            // GTM expects the currency to be in dollars
            if (selectedProductPrice?.price) {
              gtmTrackingData.price =
                selectedProductPrice.price /
                getCurrencyDivisor(selectedProductPrice.currency)
            }
            sendGTMPurchaseEvent(gtmTrackingData)
          }
        },
        false
      )
    },
    [
      checkoutUrls,
      analytics,
      selectedProductPrices,
      product,
      plusCreditsEnabled,
    ]
  )

  const bgColor = useColorModeValue('white', 'gray.700')

  return (
    <Modal
      isOpen={isUpsellModalOpen}
      trapFocus={false}
      size={purchaseComplete ? '3xl' : '6xl'}
      onClose={closeUpsellModal}
      closeOnEsc={!purchaseComplete}
      closeOnOverlayClick={!purchaseComplete}
    >
      <ModalOverlay />
      <ModalContent
        m={{ base: '0', lg: '6' }}
        bg="gray.100"
        borderRadius="xl"
        data-id="upsell-modal"
      >
        <ModalCloseButton />
        {!selectedProductPrices ? (
          <LoadingSkeleton />
        ) : purchaseComplete ? (
          <ModalContentPurchaseComplete
            isVerifying={!product}
            product={currentProduct}
            onClick={closeUpsellModal}
          />
        ) : (
          <ModalBody px={{ base: '4', lg: '12' }} pb={6}>
            <Stack pt={{ base: '4', lg: '10' }}>
              <Stack flex={1} gap={1}>
                <Heading
                  fontFamily="p22-mackinac-pro"
                  letterSpacing="tight"
                  size={{ base: 'xl', lg: '2xl' }}
                  fontWeight="800"
                >
                  <Trans>Plans and Pricing</Trans>
                </Heading>

                <Text fontSize={{ base: 'md', lg: 'lg' }} color="gray.700">
                  <Trans>
                    Remove {GAMMA_PROPER_NOUN} branding with {PLUS_PROPER_NOUN},
                    or go {PRO_PROPER_NOUN} for unlimited AI
                  </Trans>
                </Text>
              </Stack>
              {showBillingCycleSwitcher && (
                <BillingCycleSwitcher
                  mt={6}
                  mb={2}
                  discountPct={annualDiscountPercentage}
                  selectedBillingCycleKey={selectedBillingCycleKey}
                  setSelectedBillingCycleKey={
                    handleSelectedBillingCycleKeyChange
                  }
                />
              )}
            </Stack>
            <Flex
              my={{ base: '8', lg: '8' }}
              bg={bgColor}
              rounded="md"
              direction={{ base: 'column-reverse', lg: 'row' }}
              shadow="base"
              gap={{ base: '3', lg: '4' }}
            >
              <ProductDetails
                productKey="free"
                // Passing in Pro product so that we get correct symbol
                // on details page for the Free product
                productPrice={selectedProductPrices.pro}
                isCurrentProduct={!product}
                canManageWorkspace={canManageWorkspace}
              />
              <Box
                w={{ base: 'unset', lg: '1px' }}
                minH="0"
                h={{ base: '1px', lg: 'unset' }}
                bg={'gray.100'}
              />
              {showPlusUX && (
                <>
                  <ProductDetails
                    productKey="plus"
                    productPrice={
                      product === 'plus'
                        ? currentSubscriptionProductPrice
                        : selectedProductPrices.plus
                    }
                    isUpgrade={!product}
                    isCurrentProduct={product === 'plus'}
                    canManageWorkspace={canManageWorkspace}
                    upgradeDisabled={upgradeDisabled || product === 'plus'}
                    onClick={handleCheckoutClick('plus')}
                  />
                  <Box
                    w={{ base: 'unset', lg: '1px' }}
                    minH="0"
                    h={{ base: '1px', lg: 'unset' }}
                    bg={'gray.100'}
                  />
                </>
              )}
              <ProductDetails
                productKey="pro"
                productPrice={
                  product === 'pro'
                    ? currentSubscriptionProductPrice
                    : selectedProductPrices.pro
                }
                isCurrentProduct={product === 'pro'}
                isUpgrade={product ? product !== 'pro' : undefined}
                canManageWorkspace={canManageWorkspace}
                upgradeDisabled={upgradeDisabled || product === 'pro'}
                onClick={handleCheckoutClick('pro')}
              />
            </Flex>
            {!monthlyBillingEnabled && (
              <Text fontSize="sm" color="gray.500" mt={4}>
                <Trans>
                  {GAMMA_PRO_PROPER_NOUN} is only available as a yearly
                  subscription right now. We plan to add monthly billing and
                  other payment options in the future.
                </Trans>
              </Text>
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

const LoadingSkeleton = () => {
  return (
    <ModalBody>
      <Stack w="100%" px={10} pt={10}>
        <Skeleton height="60px" />
        <Skeleton height="30px" />
      </Stack>
      <Flex mt="8" rounded="xl" px={10} gap="8">
        <Stack w="100%" spacing={4}>
          <Skeleton height="300px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
        <Box
          w={{ base: 'unset', lg: '1px' }}
          minH="0"
          h={{ base: '1px', lg: 'unset' }}
          bg={'gray.100'}
        />
        <Stack w="100%" spacing={4}>
          <Skeleton height="300px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      </Flex>
    </ModalBody>
  )
}

const ModalContentPurchaseComplete = withGoogleTagManager(
  ({
    isVerifying = false,
    product,
    onClick,
  }: {
    isVerifying: boolean
    product?: ProductKey | null
    onClick?: () => void
  }) => {
    const productName = product ? getProductDetails()[product].name : ''
    return (
      <ModalBody>
        {isVerifying ? (
          <VStack py={12} spacing={4}>
            <Spinner size="lg" />
            <Heading
              fontFamily="p22-mackinac-pro"
              letterSpacing="tight"
              size="lg"
              fontWeight="semibold"
            >
              <Trans>Purchase successful</Trans>
            </Heading>
            <Text>
              <Trans>Just a sec while we set up your subscription...</Trans>
            </Text>
          </VStack>
        ) : (
          <VStack py={8} spacing={10}>
            <Box color="green.500">
              <FontAwesomeIcon icon={duotone('circle-check')} size="4x" />
            </Box>
            <Heading
              fontFamily="p22-mackinac-pro"
              letterSpacing="tight"
              size="lg"
              fontWeight="semibold"
            >
              <Trans>All done! Your subscription is now active.</Trans>
            </Heading>
            <Button size="lg" variant="solid" onClick={onClick}>
              <Trans>
                Start using {GAMMA_PROPER_NOUN} {productName}
              </Trans>
            </Button>
          </VStack>
        )}
      </ModalBody>
    )
  }
)
