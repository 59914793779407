import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Code,
  Flex,
  FlexProps,
  Image,
  Text,
} from '@chakra-ui/react'
import { gammaTheme } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'

import SalParachute from 'publicImages/Sal-Asteroid-Parachute.png'
import { colorWithOpacity } from 'utils/color'

import { CenteredMessageCard } from './CenteredMessage'

const defaultError = <Trans>Something went wrong 😕</Trans>

type ErrorComponentProps = FlexProps & {
  errMessage?: JSX.Element | string
  starOverlay?: boolean
  showDisclosure?: boolean
}

const StarOverlayStyles: FlexProps = {
  bgImage: `radial-gradient(1px 1px at 20px 30px, #eee, rgba(0,0,0,0)),
    radial-gradient(1px 1px at 40px 70px, #fff, rgba(0,0,0,0)),
    radial-gradient(1px 1px at 50px 160px, #ddd, rgba(0,0,0,0)),
    radial-gradient(1px 1px at 90px 40px, #fff, rgba(0,0,0,0)),
    radial-gradient(1px 1px at 130px 80px, #fff, rgba(0,0,0,0)),
    radial-gradient(1px 1px at 160px 120px, #ddd, rgba(0,0,0,0))`,
  bgRepeat: 'repeat',
  bgSize: '200px 200px',
  transform: 'translate(-50%,-50%)',
  borderRadius: { base: 0, lg: '100px' },
  pos: 'relative',
  maxW: '8xl',
  maxH: '5xl',
  minH: { base: 0, lg: '5xl' },
}

export const ErrorComponent = ({
  errMessage = defaultError,
  showDisclosure = false,
  opacity = 1,
  starOverlay = true,
  backgroundColor = gammaTheme.colors.trueblue[900],
  ...flexProps
}: ErrorComponentProps) => {
  const starryProps = starOverlay ? StarOverlayStyles : {}

  return (
    <Flex
      data-error-component
      w="100vw"
      h="100vh"
      overflow="hidden"
      zIndex={99999}
      backgroundColor={colorWithOpacity(
        backgroundColor as string,
        opacity as number
      )}
      {...flexProps}
    >
      <Flex
        {...starryProps}
        justifyContent="center"
        alignItems="center"
        flex={1}
        p={[4, 16]}
        left="50%"
        top="50%"
      >
        <CenteredMessageCard
          lede=""
          title={<Trans>Uh oh.</Trans>}
          stackProps={{ shadow: 'lg-dark', position: 'relative' }}
        >
          <Text>
            <Trans>
              It looks like something went wrong - try refreshing the page.
            </Trans>
          </Text>
          {showDisclosure && (
            <Accordion allowToggle pt={4}>
              <AccordionItem>
                <AccordionButton
                  px={0}
                  _hover={{ bgColor: 'none' }}
                  borderStyle={'none'}
                >
                  <Box flex="1" textAlign="left">
                    <Trans>Here are the details of what happened:</Trans>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Code colorScheme="red">{errMessage}</Code>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <Center>
            <Button
              variant="solid"
              size="lg"
              m={6}
              onClick={() => {
                window.location.reload()
              }}
            >
              <Trans>Refresh page</Trans>
            </Button>
          </Center>
          <Image
            pos="absolute"
            w={36}
            bottom={'calc(-100% + 120px)'}
            right={-12}
            src={SalParachute.src}
            pointerEvents="none"
            opacity={{ base: 0, md: 1 }}
          />
        </CenteredMessageCard>
      </Flex>
    </Flex>
  )
}
