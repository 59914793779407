import { useEffect } from 'react'

import {
  DEFAULT_GAMMA_LOCALE,
  PSEUDO_LOCALE_KEY,
  SupportedLocaleKey,
} from 'modules/i18n/constants'
import { dynamicLoadAndActivate } from 'modules/i18n/load'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { selectUserLocale } from 'modules/user/reducer'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import {
  selectPreviewLocale,
  selectPseudoLocaleActive,
  setDisplayLocale,
} from '../reducer'
import {
  getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback,
  isNavigatorLanguageGA,
} from '../utils/localeKey'
import { useLinguiPseudoLocaleSync } from './useLinguiPseudoLocale'

// Computes which locale should be used based on the user setting, the local storage cache,
// and the locale provided by the users navigator.
const getLocaleToUse = (
  userSettingLocaleKey?: string,
  localStorageLocale?: string
) => {
  const supportedLocaleKeyFromNavigatorLanguage =
    getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback() // Always a supported Gamma locale (falls back to english)
  const navigatorLanguageGA = isNavigatorLanguageGA()

  // The app always loads in english first
  const localeToLoad = userSettingLocaleKey
    ? userSettingLocaleKey // 1. Prefer the actual user setting value
    : localStorageLocale
    ? localStorageLocale // 2. Then use the last known value
    : navigatorLanguageGA
    ? supportedLocaleKeyFromNavigatorLanguage // 3. Then use the browser language (if supported)
    : DEFAULT_GAMMA_LOCALE // 4. Finally, default to english

  return localeToLoad
}

export function useLinguiInit() {
  const dispatch = useAppDispatch()

  useLinguiPseudoLocaleSync()

  const previewLocale = useAppSelector(selectPreviewLocale)
  const isPseudoLocaleActive = useAppSelector(selectPseudoLocaleActive)
  const userSettingLocaleKey = useAppSelector(selectUserLocale)
  const validLocaleFromNavigator =
    isNavigatorLanguageGA() &&
    getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback()
  // The latest known value of your user setting locale
  const [localStorageLocale, setLocalStorageLocale] = useLocalStorage<string>(
    USER_SETTINGS_CONSTANTS.gammaLocale,
    validLocaleFromNavigator || DEFAULT_GAMMA_LOCALE
  )

  const localeToLoad = getLocaleToUse(
    userSettingLocaleKey,
    localStorageLocale
  ) as SupportedLocaleKey

  // Sync the localeToLoad into redux
  useEffect(() => {
    dispatch(setDisplayLocale({ locale: localeToLoad }))
  }, [dispatch, localeToLoad])

  useEffect(() => {
    if (isPseudoLocaleActive) {
      dynamicLoadAndActivate(PSEUDO_LOCALE_KEY)
      return
    }
    if (previewLocale) {
      dynamicLoadAndActivate(previewLocale)
      return
    }
    if (localeToLoad) {
      dynamicLoadAndActivate(localeToLoad)
    }
  }, [isPseudoLocaleActive, previewLocale, localeToLoad])

  useEffect(() => {
    // Always mirror the users.settings.locale into localStorage
    if (userSettingLocaleKey) {
      setLocalStorageLocale(userSettingLocaleKey)
    } else if (validLocaleFromNavigator) {
      setLocalStorageLocale(validLocaleFromNavigator)
    } else {
      setLocalStorageLocale(DEFAULT_GAMMA_LOCALE)
    }
  }, [userSettingLocaleKey, setLocalStorageLocale, validLocaleFromNavigator])
}
