import { useDisclosure } from '@chakra-ui/react'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { ProductPrice } from 'modules/api'
import {
  FrequencyUnit,
  GetWorkspaceSubscriptionQuery,
  useGetWorkspaceSubscriptionQuery,
  useProductsQuery,
} from 'modules/api/generated/graphql'
import { ChurnkeyScript, useChurnkeyFailedPaymentCheck } from 'modules/churnkey'
import { useFeatureFlag } from 'modules/featureFlags'
import { replaceState } from 'modules/history'
import { SegmentEvents } from 'modules/segment'
import { useAnalytics } from 'modules/segment/SegmentContextProvider'
import { useUserContext } from 'modules/user/context'
import { getExistingQueryParams } from 'utils/url'

import { UpsellModalPro } from '../components/UpsellModal'
import { PaidProductKey } from '../types'
import { getProductForWorkspace } from '../utils'

const GET_PRO_QUERY_PARAM = 'get-gamma-pro'

type OpenUpsellModalArgs = {
  onOpenCallback?: () => void
  onCloseCallback?: () => void
  segmentEvent: SegmentEvents
}
type MonetizationContextType = {
  openUpsellModal: (args: OpenUpsellModalArgs) => void
  refetchSubscription?: ReturnType<
    typeof useGetWorkspaceSubscriptionQuery
  >['refetch']
  product: PaidProductKey | null
  subscription: GetWorkspaceSubscriptionQuery['subscription']
  proYearlyProductPrice: ProductPrice | undefined
}
export const MonetizationContext = React.createContext<MonetizationContextType>(
  {
    openUpsellModal: () => {},
    product: null,
    subscription: undefined,
    proYearlyProductPrice: undefined,
  }
)

export const useMonetizationContext = () => {
  const ctx = useContext(MonetizationContext)
  return ctx
}

type MonetizationContextProps = {
  children: React.ReactNode
}

export const MonetizationContextProvider = ({
  children,
}: MonetizationContextProps): JSX.Element => {
  const { currentWorkspace, isUserLoading } = useUserContext()
  const analytics = useAnalytics()
  const upsellUXEnabled_Pro = useFeatureFlag('upsellUXEnabled_Pro')
  const { data: subscriptionData, refetch } = useGetWorkspaceSubscriptionQuery({
    variables: {
      workspaceId: currentWorkspace?.id as string,
      includeExpired: true,
    },
    skip: !currentWorkspace?.id,
  })
  const subscription = subscriptionData?.subscription

  useChurnkeyFailedPaymentCheck({
    subscription,
    workspace: currentWorkspace,
    autoLaunch: true,
  })

  const { data: productsData } = useProductsQuery({
    variables: {
      workspaceId: currentWorkspace?.id as string,
    },
    skip: !currentWorkspace,
  })
  const proProduct = productsData?.products?.find((p) => p.key === 'pro')
  const proYearlyProductPrice = proProduct?.prices?.find(
    (p) => p.frequencyUnit === FrequencyUnit.Year
  )

  // Product will be null if the subscription is expired
  const product = getProductForWorkspace(currentWorkspace)

  const {
    isOpen: isUpsellModalOpen,
    onOpen: onUpsellModalOpen,
    onClose: onUpsellModalClose,
  } = useDisclosure({ id: 'upsell-modal' })

  const tempOnCloseCallback = useRef<(() => void) | null>(null)

  const openUpsellModal = useCallback(
    ({
      onOpenCallback,
      onCloseCallback,
      segmentEvent,
    }: OpenUpsellModalArgs) => {
      if (!upsellUXEnabled_Pro) {
        return
      }

      onOpenCallback?.()
      onUpsellModalOpen()
      analytics?.track(segmentEvent)
      tempOnCloseCallback.current = onCloseCallback || null
    },
    [analytics, onUpsellModalOpen, upsellUXEnabled_Pro]
  )

  const closeUpsellModal = useCallback(() => {
    tempOnCloseCallback?.current?.()
    onUpsellModalClose()
    tempOnCloseCallback.current = null
  }, [onUpsellModalClose])

  const [contextState, setContextState] = useState<MonetizationContextType>({
    openUpsellModal,
    product,
    subscription,
    proYearlyProductPrice: proYearlyProductPrice,
  })

  useEffect(() => {
    const hasQueryParam =
      getExistingQueryParams()[GET_PRO_QUERY_PARAM] === 'true'
    if (!hasQueryParam || isUserLoading || !currentWorkspace) return

    onUpsellModalOpen()
    // Strip the query parameter
    replaceState({
      query: {
        ...getExistingQueryParams(),
        [GET_PRO_QUERY_PARAM]: undefined,
      },
      emitChange: false,
    })
  }, [onUpsellModalOpen, currentWorkspace, isUserLoading])

  useEffect(() => {
    // Only update the context state if the values have changed
    // So that each rerender of the context provider doesn't cause
    // unnecessary rerenders of all the components that use the context.
    setContextState({
      openUpsellModal,
      refetchSubscription: refetch,
      product,
      subscription,
      proYearlyProductPrice,
    })
  }, [openUpsellModal, product, subscription, proYearlyProductPrice, refetch])

  return (
    <MonetizationContext.Provider value={contextState}>
      {children}

      <UpsellModalPro
        isUpsellModalOpen={isUpsellModalOpen}
        closeUpsellModal={closeUpsellModal}
      />
      <ChurnkeyScript />
    </MonetizationContext.Provider>
  )
}
