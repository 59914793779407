import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

import { config } from 'config'

const ERROR_PREFIX = 'UNKNOWN_ERROR'

export const useBotDetection = () => {
  useEffect(() => {
    if (config.APPLICATION_ENVIRONMENT !== 'production') return

    // Create event listeners for common events (on capture phase) and check if the event is trusted
    // https://developer.mozilla.org/en-US/docs/Web/API/Event/isTrusted
    const pasteListener = (e: Event) => {
      if (!e.isTrusted) {
        Sentry.captureException(`${ERROR_PREFIX}__0_ps`)
      }
    }
    const mouseDownListener = (e: Event) => {
      if (!e.isTrusted) {
        Sentry.captureException(`${ERROR_PREFIX}__2_md`)
      }
    }
    const keyDownListener = (e: KeyboardEvent) => {
      if (!e.isTrusted) {
        Sentry.captureException(`${ERROR_PREFIX}__2_kd`)
      }
    }

    document.addEventListener('paste', pasteListener, { capture: true })
    document.addEventListener('mousedown', mouseDownListener, {
      capture: true,
    })
    document.addEventListener('keydown', keyDownListener, { capture: true })

    return () => {
      document.removeEventListener('paste', pasteListener, { capture: true })
      document.removeEventListener('mousedown', mouseDownListener, {
        capture: true,
      })
      document.removeEventListener('keydown', keyDownListener, {
        capture: true,
      })
    }
  }, [])
}
