import { Image, Kbd, Stack, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { StepType } from '@reactour/tour'
import omit from 'lodash/omit'

import { FeatureFlags } from 'modules/featureFlags'
import { baseStyles } from 'modules/guiders/components/baseStyles'
import { GAMMA_ARTIFACT_PROPER_NOUN } from 'modules/i18n/properNouns'
import SlashDemo from 'publicImages/guiders/slashmenudemo.gif'
import { elementReady } from 'utils/dom'

import { StepContent } from '../../components/StepContent'
const RIGHT_ARROW_CHAR = '→'
const LEFT_ARROW_CHAR = '←'
const DOWN_ARROW_CHAR = '↓'
const UP_ARROW_CHAR = '↑'
const ESC_SHORTCUT = 'Esc'
const SLASH_CHAR = '/'

const PRESENT_BUTTON_GROUP_SELECTOR =
  '[data-guider-highlight="present-button-group"]'
const MODAL_CLOSE_BUTTON_SELECTOR = '.chakra-modal__close-btn'

const exitPresentMode = () => {
  const presentButtonGroup = document.querySelector(
    PRESENT_BUTTON_GROUP_SELECTOR
  ) as HTMLButtonElement
  if (presentButtonGroup?.dataset?.editorMode === 'slide') {
    const presentButton = presentButtonGroup.querySelector(
      '[data-present-button]'
    ) as HTMLButtonElement
    presentButton.click()
  }
}

const disablePresentButton = () => {
  const presentButtonGroup = document.querySelector(
    PRESENT_BUTTON_GROUP_SELECTOR
  ) as HTMLButtonElement
  if (presentButtonGroup) {
    presentButtonGroup.style.pointerEvents = 'none'
  }
}

export const enablePresentButton = () => {
  const presentButtonGroup = document.querySelector(
    PRESENT_BUTTON_GROUP_SELECTOR
  ) as HTMLButtonElement
  if (presentButtonGroup) {
    presentButtonGroup.style.pointerEvents = 'initial'
  }
}

const closeAnyModals = () => {
  let closeButtons = document.querySelectorAll(MODAL_CLOSE_BUTTON_SELECTOR)
  if (closeButtons.length) {
    closeButtons.forEach((n) => (n as HTMLButtonElement).click())
  }
  // Super edge case-y, but if someone is in the doc theme drawer, and then they open the
  // create theme modal, then we need to actually call this twice D:
  setTimeout(() => {
    closeButtons = document.querySelectorAll(MODAL_CLOSE_BUTTON_SELECTOR)
    if (closeButtons.length) {
      closeButtons.forEach((n) => (n as HTMLButtonElement).click())
    }
  }, 1000)
}

const closeModalsAndPanels = () => {
  closeAnyModals()
  closeAiDesignerIfOpen()
  closeCommentsPanelIfOpen()
}
const closeAiDesignerIfOpen = () => {
  const closeButton = document.querySelector(
    `[data-design-partner-close-button]`
  )
  if (closeButton) {
    ;(closeButton as HTMLButtonElement).click()
  }
}

const closeCommentsPanelIfOpen = () => {
  const closeButton = document.querySelector(`[data-comment-feed-close-button]`)
  if (closeButton) {
    ;(closeButton as HTMLButtonElement).click()
  }
}

export const getDocEditorTourSteps = (
  featureFlags: Pick<FeatureFlags, 'aiChat' | 'filmstrip'>
): StepType[] => {
  return DOC_EDITOR_TOUR_STEPS.filter((step) => {
    if (step.featureFlag) {
      return featureFlags[step.featureFlag]
    }
    if (step.disableIfFeatureFlag) {
      return !featureFlags[step.disableIfFeatureFlag]
    }
    return true
  }).map((step) => {
    return omit(step, ['featureFlag'])
  })
}

export const DOC_EDITOR_TOUR_STEPS: Array<
  StepType & {
    featureFlag?: keyof FeatureFlags
    disableIfFeatureFlag?: keyof FeatureFlags
  }
> = (
  [
    // Welcome
    {
      position: 'center',
      selector: '#editor-core-root',
      content: function StepContentWrapper() {
        return (
          <StepContent
            title={
              <>
                👋
                <Trans>Welcome to the editor!</Trans>
              </>
            }
            body={
              <Trans>This is a quick tour to show you how to use Gamma.</Trans>
            }
          />
        )
      },
      styles: {
        ...baseStyles,
        ...{
          maskArea: (base) => {
            const maskAreaBaseStyles =
              baseStyles && baseStyles.maskArea && baseStyles.maskArea(base)
            return {
              ...maskAreaBaseStyles,
              width: 0,
              height: 0,
            }
          },
        },
      },
      action: () => {
        exitPresentMode()
        elementReady(PRESENT_BUTTON_GROUP_SELECTOR).then(
          (presentButtonGroup) => {
            if (presentButtonGroup) {
              presentButtonGroup.style.pointerEvents = 'none'
            }
          }
        )
      },
    },

    // Insert widget
    {
      selector: '[data-guider-highlight="insert-widget"]',
      position: 'bottom',
      content: (
        <StepContent
          title={<Trans>Insert widget</Trans>}
          body={
            <Trans>
              Use this to insert content into your page. You can add text,
              images, layouts, videos, and more.
            </Trans>
          }
        />
      ),
    },

    // Slash command
    {
      selector: '[data-guider-highlight="card-body"]',
      content: (
        <StepContent
          title={<Trans>Try the slash menu</Trans>}
          body={
            <Stack spacing={4}>
              <Text size="sm">
                <Trans>
                  Type <Kbd>{SLASH_CHAR}</Kbd> in a card to browse and insert
                  content blocks, just like Notion or Confluence.
                </Trans>
              </Text>
              <Image src={SlashDemo.src} />
            </Stack>
          }
        />
      ),
      highlightedSelectors: [
        '[data-guider-highlight="card-body"]',
        '[data-slash-menu-dropdown]',
      ],
      mutationObservables: [
        '[data-guider-highlight="card-body"]',
        '[data-slash-menu-dropdown]',
      ],
      resizeObservables: [
        '[data-guider-highlight="card-body"]',
        '[data-slash-menu-dropdown]',
      ],
    },

    // Add card
    {
      selector:
        '[data-is-nested-card="false"] [data-guider-highlight="add-card-button"]',
      mutationObservables: [
        '[data-is-nested-card="false"] [data-guider-highlight="add-card-button"]',
      ],
      resizeObservables: [
        '[data-card-body]',
        '[data-is-nested-card="false"] [data-guider-highlight="add-card-button"]',
      ],
      position: 'top',
      content: (
        <StepContent
          title={<Trans>Add cards</Trans>}
          body={
            <Trans>
              Click the '+' to add cards to your page, or the astronaut icon to
              add an AI-generated card. You can also click the dropdown to
              browse premade card templates.
            </Trans>
          }
        />
      ),
      action: (elem) => {
        if (elem?.parentElement) {
          elem.parentElement.style.opacity = '1'
        }
      },
    },

    // Table of Contents
    {
      disableIfFeatureFlag: 'filmstrip',
      selector: '[data-guider-highlight="table-of-contents-opener"]',
      content: (
        <StepContent
          title={<Trans>Table of contents</Trans>}
          body={
            <Trans>
              Hover to get a bird's eye view of the cards on this page. Click to
              jump to that card, or drag to reorder cards.
            </Trans>
          }
        />
      ),
      highlightedSelectors: [
        '[data-guider-highlight="table-of-contents-opener"]',
        // '[data-table-of-contents]',
      ],
      // mutationObservables: ['[data-table-of-contents]'],
      // resizeObservables: ['[data-table-of-contents]'],
    },

    // Filmstrip
    {
      featureFlag: 'filmstrip',
      position: 'right',
      // This selector is for both the filmstrip open button, and the filmstrip itself
      selector: '[data-guider-highlight="filmstrip"]',
      content: (
        <StepContent
          title={<Trans>Filmstrip</Trans>}
          body={
            <Trans>
              Use the filmstrip to easily navigate between cards, or drag to
              reorder cards. Right click to add a new card, duplicate, and more.
            </Trans>
          }
        />
      ),

      resizeObservables: ['[data-guider-highlight="filmstrip"]'],
    },

    // Theme
    {
      selector: '[data-guider-highlight="toolbar-theme-button"]',
      content: (
        <StepContent
          title={<Trans>Create a theme</Trans>}
          body={
            <Trans>
              Make your {GAMMA_ARTIFACT_PROPER_NOUN} polished and on brand in a
              few clicks. Add your logo, colors, brand fonts and more.
            </Trans>
          }
        />
      ),
    },

    // Share
    {
      selector: '[data-guider-highlight="share-button"]',
      content: (
        <StepContent
          title={<Trans>Share your work</Trans>}
          body={
            <Trans>
              Open the share panel to share your {GAMMA_ARTIFACT_PROPER_NOUN}{' '}
              with others, or export to PDF or PPT.
            </Trans>
          }
        />
      ),
    },

    // Present
    {
      selector: PRESENT_BUTTON_GROUP_SELECTOR,
      content: (
        <StepContent
          title={<Trans>Present your {GAMMA_ARTIFACT_PROPER_NOUN}</Trans>}
          body={
            <Text size="sm">
              <Trans>
                Test out present mode. Use the arrow keys{' '}
                <Kbd size="md">{UP_ARROW_CHAR}</Kbd>{' '}
                <Kbd size="md">{DOWN_ARROW_CHAR}</Kbd>{' '}
                <Kbd size="md">{LEFT_ARROW_CHAR}</Kbd>{' '}
                <Kbd size="md">{RIGHT_ARROW_CHAR}</Kbd> to move. Press{' '}
                <Kbd size="md">{ESC_SHORTCUT}</Kbd> to get out of present mode.
              </Trans>
            </Text>
          }
        />
      ),
      action: (elem) => {
        enablePresentButton()
      },
    },

    // AI Design Partner
    {
      featureFlag: 'aiChat',
      selector: '[data-guider-highlight="design-partner-button"]',
      content: (
        <StepContent
          title={<Trans>Edit with AI</Trans>}
          body={
            <Text size="sm">
              <Trans>
                Refine your page using AI. You can add cards, find images,
                reformat content, and more!
              </Trans>
            </Text>
          }
        />
      ),
      highlightedSelectors: ['[data-guider-highlight="design-partner-button"]'],
      mutationObservables: [
        '[data-guider-highlight="design-partner-button"]',
        '[data-panelid="design-partner-panel"]',
        '[data-editor-toolbar]',
        '[data-animate-value]',
      ],
      resizeObservables: [
        '[data-guider-highlight="design-partner-button"]',
        '[data-panelid="design-partner-panel"]',
        '[data-editor-toolbar]',
        '[data-animate-value]',
      ],
    },

    // View analytics
    {
      selector: '[data-guider-highlight="view-analytics-button"]',
      content: (
        <StepContent
          title={<Trans>Explore analytics</Trans>}
          body={
            <Text size="sm">
              <Trans>
                See who's viewed your {GAMMA_ARTIFACT_PROPER_NOUN}, and track
                engagement down to the card level.
              </Trans>
            </Text>
          }
        />
      ),

      highlightedSelectors: ['[data-guider-highlight="view-analytics-button"]'],
      mutationObservables: [
        '[data-guider-highlight="view-analytics-button"]',
        '[data-panelid="design-partner-panel"]',
        '[data-editor-toolbar]',
      ],
      resizeObservables: [
        '[data-guider-highlight="view-analytics-button"]',
        '[data-panelid="design-partner-panel"]',
        '[data-editor-toolbar]',
      ],
    },

    // End
    {
      selector: '[data-guider-highlight="default-help-widget-activator"]',
      content: function StepContentWrapper() {
        return (
          <StepContent
            body={
              <Text size="sm">
                <Trans>
                  If you want to go through this tour again, just click{' '}
                  <strong>Take the editor tour</strong> in the{' '}
                  <strong>Help</strong> menu.
                </Trans>
              </Text>
            }
          />
        )
      },
      styles: {
        ...baseStyles,
        ...{
          close: (base) => ({
            ...base,
            display: 'none',
          }),
        },
      },
    },
  ] as StepType[]
).map((step) => ({
  ...step,
  action: (elem) => {
    // By default, disable present mode buttons because the keyboard listeners are AGGRO AF. We'll enable it when we need it.
    disablePresentButton()
    if (step.action) {
      step.action(elem)
    }
  },
  actionAfter: (elem) => {
    // Always exit present mode in case we're in it, before proceeding to the next step.
    exitPresentMode()
    closeModalsAndPanels()

    if (step.actionAfter) {
      step.actionAfter(elem)
    }
  },
}))
